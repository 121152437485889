import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { Button } from 'primereact/button';

const ExportExcel = ({ excelData, fileName }) => {
    const fileType = ""
    const fileExtension = ".xlsx";

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws, 'data2': ws }, SheetNames: ['data', 'data2'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }




    return (


        <Button onClick={(e) => exportToExcel(fileName)} style={{ marginRight: '10px' }} icon="pi" className="p-button"
        >Export
        </Button>

    )

}

export default ExportExcel