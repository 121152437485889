import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { Button } from 'primereact/button';
import { overload } from 'quill';

const ExportGroupExcel = ({ excelData, enable, fileName }) => {
    const fileType = ""
    const fileExtension = ".xlsx";

    const exportGroupExcel = async () => {

        let sheet_data = [];

        for (let i = 0; i < excelData.length; i++) {
            let sheetname = excelData[i].sheetTitle.replace(/\s/g, '');
            let single_sheet_data = [];
            let _data = {}

            Object.entries(excelData[i].sheetData).map(([key, value]) => {

                let temp = {}
                value = Object.entries(value).reverse().map(([key, value], index) => {
                    temp = { ...temp, ...{ [key.toLocaleUpperCase()]: value } }
                    return temp;
                });

                let next = {
                    ...value[value.length - 1]
                }

                single_sheet_data.push(next);
            });

            let _ws = XLSX.utils.json_to_sheet(single_sheet_data)

            let workSheet = {
                [sheetname]: _ws
            }

            sheet_data.push(workSheet);
        }

        let wsObject = {};
        sheet_data.forEach(element => {
            wsObject = { ...wsObject, ...element }
        });


        let sheetsname = Object.entries(sheet_data).map(([key, value]) => {
            return Object.keys(value)[0].replace(/\s/g, '')
        })

        const wb = { Sheets: wsObject, SheetNames: sheetsname };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }



    return (


        <Button onClick={(e) => exportGroupExcel(fileName)} disabled={enable} style={{ marginRight: '10px' }} icon="pi" className="p-button"
        >Export
        </Button>

    )

}

export default ExportGroupExcel