import { generateRandomColors } from './Constants'

let _collectionColorCodeJson = {
    "DAA": 88,
    "DTP": 277,
    "AUR": 126,
    "SMB": 69,
    "OKBR": 258,
    "DGOD": 31,
    "CETS": 239,
    "GGSG": 296,
    "DBR": 50,
    "CLNX": 164,
    "MBRD": 220,
    "BAYC": 334,
    "CYKG": 202,
    "JIRA": 315,
    "CCAT": 145,
    "SLGT": 107,
    "GRIM": 353,
    "YOTS": 183,
    "MBPK": 12,
    "YYG":180,
    "HEL":199,
    "KJK":20,
    "SAS":220
}

const setCollectionColorCodeJson = (json) => {
    _collectionColorCodeJson = json;
    Object.freeze(_collectionColorCodeJson);
}

export const getColorCodeForCommunity = (key) => {
    if(!_collectionColorCodeJson[key]){
        return 50;
    }
    else{
    return _collectionColorCodeJson[key];
    }
}

export const mapCommunityColorCode = (_collectionList) => {

    // *******comment random generation now***********

    /* let starterObj = {
        DAA: 135
    };

    let color_hsls = generateRandomColors(_collectionList.length);
    let _collection_list_colorAdded = _collectionList.map((e, index) => {
        let key = e.code;
        let cr = color_hsls[index]
        starterObj[key] = cr;
    })

    setCollectionColorCodeJson(starterObj) */

}
