import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { GlobalContext, GlobalDispatchContext } from "../context/Context"

import LoginContainer from '../login/LoginContainer';
import AttributeContainer from '../attribute/AttributeContainer'
import UserContainer from '../user/UserContainer'
import CampaignContainer from "../campaign/CampaignContainer";
import { BUILD_VERSION, RENDER_URL,LOGOUT } from '../common/Constants'
import { httpInterceptor, setBaseUrl, setApplicationToken } from "../core/HttpService"
import { ManageLocalStorage } from "./LocalStorage";
import { ManageSessionStorage } from "./SessionStorage";
import NewCampaignContainer from "../campaign/NewCampaignContainer";
import DashBoardContainerNew from "../dashboard/DashBoardContainerNew";
import AttributeAssets from "../attribute/AttributeAssets";
import AvatarAddContainer from "../dashboard/avatar/AvatarAddContainer";
import AssetsAddContainer from "../dashboard/assets/AssetsAddContainer";
import Form from "../campaign/Form";
import ReleaseContainer from "../release/ReleaseContainer";
import NewReleaseContainer from "../release/NewReleaseContainer";
import NewsContainer from '../News/NewsContainer';
import AddNewsContainer from '../News/AddNewContainer'
import SingleNews from "../News/SingleNews";
import SingleRelease from "../release/SingleRelease";
import CampaignStatistics from "../campaign/CampaignStatistics";
import TournamentContainer from '../tournament/TournamentContainer';
import NewTournamentContainer from '../tournament/NewTournamentContainer';
import TournamentUpdate from "../tournament/TournamentUpdate";
import TournamentStatistics from "../tournament/TournamentStatics";

const RootRouteGuard = ({ xComponent: Component, ...xProps }) => {

    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)

    const clearBrowser = (e) => {
        dispatch({
            type: LOGOUT,
        });

        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
        setBaseUrl(process.env.REACT_APP_BASE_URL);
    }

    setBaseUrl(process.env.REACT_APP_BASE_URL);
    setApplicationToken(process.env.REACT_APP_TOKEN);
    httpInterceptor();


    const loginData = context.login;
    console.log(`Login Data : `,loginData);

    const _buildNo = ManageLocalStorage.get(BUILD_VERSION);
    console.log("buildNo", _buildNo);
    if (_buildNo == null || _buildNo != process.env.REACT_APP_BUILD_VERSION) {
        console.log("ENV", process.env.REACT_APP_BUILD_VERSION)
        clearBrowser(null);
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    } else {
        ManageLocalStorage.set(BUILD_VERSION, process.env.REACT_APP_BUILD_VERSION)
    }



    return (
        <Routes {...xProps}>
            <Route path={RENDER_URL.LOGIN_URL} exact element={(loginData.isLoggedIn && loginData.userData.permission.admin) ? <Navigate to={RENDER_URL.HOME_URL} /> : <LoginContainer />} />
            {<Route path={RENDER_URL.HOME_URL} exact element={(loginData.isLoggedIn && (loginData.userData.permission.admin ?  <DashBoardContainerNew /> : <Navigate to={RENDER_URL.TOURNAMENT_URL} />))} />}
            <Route path={RENDER_URL.ATTRIBUTE_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <AttributeContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.ASSET_ATTRIBUTE_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <AttributeAssets /> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.USER_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <UserContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.CAMP_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <CampaignContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.NEW_CAMP_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <NewCampaignContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.NEW_AVATAR_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <AvatarAddContainer /> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.NEW_ASSETS_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <AssetsAddContainer/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.RELEASE_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <ReleaseContainer/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.CREATE_RELEASE_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <NewReleaseContainer/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.NEWS_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <NewsContainer/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.CREATE_NEWS_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <AddNewsContainer/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.SINGLE_NEW} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <SingleNews/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.SINGLE_RELEASE} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <SingleRelease/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.CAMP_STAT_URL} exact element={((loginData.isLoggedIn && loginData.userData.permission.admin) ? <CampaignStatistics/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.TOURNAMENT_STAT_URL} exact element={(loginData.isLoggedIn ? <TournamentStatistics/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.TOURNAMENT_URL} exact element={(loginData.isLoggedIn ? <TournamentContainer/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.NEW_TOURNAMENT_URL} exact element={(loginData.isLoggedIn ? <NewTournamentContainer/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            <Route path={RENDER_URL.UPDATE_TOURNAMENT_URL} exact element={(loginData.isLoggedIn ? <TournamentUpdate/> : <Navigate to={RENDER_URL.LOGIN_URL} />)} />
            

        </Routes>
    );
};
export default RootRouteGuard