import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import './Loader.css'
import { GlobalContext,GlobalDispatchContext } from "../context/Context"
import { ManageLocalStorage } from '../core/LocalStorage';
import { LOGOUT, RENDER_URL } from './Constants';


function LoginHeaderContainer(props) {

    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)
    const showLoader = context.common.isLoading;
    const _history = useNavigate();

    const clearBrowser = (e) => {
        
        e.preventDefault();
        dispatch({
            type: LOGOUT,
        });
        ManageLocalStorage.clear();

    }

    return (
        <>
            {showLoader && <div className='sample_loader'>
                <div className='loader_center'>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            </div>}
        </>

    )

}

export default LoginHeaderContainer;