import { httpCall, httpFormCall,httpUpload } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const addNewsApi = params => {

    console.log(params);
    return httpUpload({
        url: API_URL.CREATE_NEW_URL,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const updateNewsApi = params => {
    return httpUpload({
        url: API_URL.UPDATE_NEWS_URL,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

export const publishNewsApi = params => {
    return httpCall({
        url: API_URL.PUBLISH_NEWS_API,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const getAllNew = params =>{
    return httpCall({
        url: API_URL.GET_ALL_NEWS,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
}

export const getCampaignListCompleted = params => {
    return httpCall({
        url: API_URL.CAMPAIGN_LIST_NOT,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};
