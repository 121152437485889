import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import { getAllVersion } from './ReleaseService';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { RENDER_URL, STOP_LOADING, START_LOADING, CREATE_RELEASE_URL } from '../common/Constants';
import { Editor } from "primereact/editor";
import { Tag } from 'primereact/tag';

function ReleaseContainer(props) {

    const _history = useNavigate();
    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;
    const [activeIndex, setActiveIndex] = useState(0);
    const [addMode, setAddMode] = useState(userData.permission.add);

    const [releaseList, setReleaseList] = useState([]);
    const [completedReleaseList, setCompletedReleaseList] = useState([]);

    useEffect(() => {

        loadAllVersion();

    }, []);

    const loadAllVersion = () => {
        dispatch({
            type: START_LOADING,
        });

        getAllVersion().then((response) => {

            if (response.data.success) {

                let temp = response.data.data;
                console.log(temp);
                setReleaseList(temp.length > 0 ? [temp[0]] : []);
                setCompletedReleaseList(temp.length > 0 ? temp.slice(1, temp.length) : [])
                dispatch({
                    type: STOP_LOADING,
                });
            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });

        })

    }


    const loadNewRelease = () => {
        _history(RENDER_URL.CREATE_RELEASE_URL)
    }

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
            </span>
        );
    };


    const renderDataTableHeader = () => {
        return (
            <div className="flex justify-content-between" style={{ margin: '10px' }}>
                <Button type="button" style={{ visibility: 'hidden' }} icon="pi pi-filter-slash" label="Clear Filters" className="p-button-outlined" />
                <div style={{ float: 'right' }}>
                    {addMode && <Button type="button" style={{ marginLeft: '10px' }} icon="pi pi-user" label="Add New Release" className="p-button-outlined" onClick={() => { loadNewRelease() }} />}
                </div>
            </div>
        )
    }

    const htmlViewTemplate = (htmlData) => {

        return <Editor value={htmlData.notes} readOnly headerTemplate={renderHeader} style={{ minHeight: '150px' }} />
    };


    const addTimeTemplate = (rowData) => {
        return <Moment format="YYYY/MM/DD">
            {rowData.addTime}
        </Moment>
    }

    const modifiedTimeTemplate = (rowData) => {
        return <Moment format="YYYY/MM/DD">
            {rowData.lastModified}
        </Moment>
    }
    const versionTemplate = (rowData) => {

        return <React.Fragment>
            <Tag severity="info" value={rowData.version}></Tag>
        </React.Fragment>
    }

    const onDataRowClick = (event) => {
        console.log(event.data);
        _history(RENDER_URL.SINGLE_RELEASE, { state: { DATA: event.data } });
    }




    return (
        <main id="main">
            <HeaderContainer />

            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">

                    {renderDataTableHeader()}

                    <div className="card">

                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>

                            <TabPanel header="Latest" leftIcon="pi pi-fw">
                                <DataTable value={releaseList} responsiveLayout="scroll" onRowClick={(e) => onDataRowClick(e)}>
                                    <Column field="version" header="Version" body={versionTemplate} ></Column>
                                    {/* <Column field="notes" header="ReleaseNote" body={htmlViewTemplate}></Column> */}
                                    <Column field="addTime" header="Added Time" body={addTimeTemplate}></Column>
                                    <Column field="lastModified" header="Last Modified" body={modifiedTimeTemplate}></Column>
                                </DataTable>
                            </TabPanel>

                            <TabPanel header="Old Versions" leftIcon="pi pi-fw">
                                <DataTable value={completedReleaseList} onRowClick={(e) => onDataRowClick(e)} responsiveLayout="scroll">
                                    <Column field="version" header="Version" body={versionTemplate} ></Column>
                                    {/* <Column field="notes" header="ReleaseNote" body={htmlViewTemplate}></Column> */}
                                    <Column field="addTime" header="Added Time" body={addTimeTemplate}></Column>
                                    <Column field="lastModified" header="Last Modified" body={modifiedTimeTemplate}></Column>
                                </DataTable>
                            </TabPanel>

                        </TabView>

                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );
}

export default ReleaseContainer;