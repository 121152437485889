import React, { useState, useEffect, useRef } from 'react';
import { useNavigate,useLocation } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import moment from 'moment';
import { updateReleaseNote } from './ReleaseService';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { STOP_LOADING, START_LOADING, RENDER_URL } from '../common/Constants';
import { Toast } from 'primereact/toast';
import { Editor } from "primereact/editor";
import { InputTextarea } from 'primereact/inputtextarea';

function SingleRelease(props) {


    const _history = useNavigate();
    const location = useLocation();

    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;

    const data = location.state.DATA;

    const [selectedFile, setSelectedFile] = React.useState(null);

    const [campaignName, setCampaignName] = useState("");
    const [campaignNameError, setErrorCampaignName] = useState("");
    const [releaseTitle, setReleaseTitle] = useState(data.version);
    const [releaseTitleError, setErrorReleaseTitle] = useState("");

    const [releaseNote, setReleaseText] = useState(data.notes);
    const [releaseNoteError, setReleaseTextError] = useState('');


    const toast_ = useRef(null);

    const clearValidation = () => {
        setErrorReleaseTitle("")
    }

    const validation = () => {

        clearValidation()
        if (releaseTitle == "") {
            setErrorReleaseTitle("Enter Version");
            return false;
        }
        else if (releaseNote == "") {
            setReleaseTextError("Enter Release note");
            return false;
        }
        else {
            return true
        }
    }




    const onCancelButtonClick = () => {
        _history(RENDER_URL.RELEASE_URL);
    }

    const onSaveButtonClick = () => {
        if (validation()) {

            let param = {
                content : releaseNote,
                version:releaseTitle
            }

            dispatch({
                type: START_LOADING,
            });
       
            updateReleaseNote(param).then((response) => {

                if (response.data.success) {
                
                    toast_.current.show({ severity: 'success', summary: `${response.data.message}`, detail: '', life: 3000 });
                    
                    setTimeout(()=>{
                        dispatch({
                            type: STOP_LOADING,
                        });
    
                        _history(RENDER_URL.RELEASE_URL)
                    },1000)
                    
                }
            }).catch((error) => {
                console.log(error);
                dispatch({
                    type: STOP_LOADING,
                });
            })

        }
    }


    return (
        <main id="main">
            <HeaderContainer />

            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">
                    <Toast ref={toast_} position="top-center" />
                    <div className="card" style={{ width: '100%' }}>
                        <section className='section_campaign'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Release Version</label>
                                            <input type="text" className="form-control" placeholder="Enter version" value={releaseTitle} onChange={(e) => { (e.target.value.length > 100) ? setErrorReleaseTitle("Max 100 characters") : setReleaseTitle(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {releaseTitleError}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>Release Note</label>
                                            <br></br>
                                            <InputTextarea value={releaseNote} onChange={(e) => setReleaseText(e.target.value)} rows={5} cols={30} style={{ height: '320px',width:'100%' }} />
                                           {/*  <Editor value={releaseNote} onTextChange={(e) => setReleaseText(e.htmlValue)} style={{ height: '320px' }} /> */}
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {releaseNoteError}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group" style={{ float: 'right' }}>
                                            <Button type="button" icon="pi" label="Cancel" className="p-button-outlined" onClick={() => { onCancelButtonClick() }} />
                                            <Button label="Update" style={{ marginLeft: '5px' }} icon="pi pi-check" className="p-button-success" autoFocus onClick={() => { onSaveButtonClick() }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );
}

export default SingleRelease;