import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import moment from 'moment';
import { geTournamentList, geTournamentListCompleted } from './TournamentService';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING, ITEM_COLLECTION, ASSET_COLLECTION, SET_ASSET, SET_ITEM } from '../common/Constants';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { ManageLocalStorage } from '../core/LocalStorage';
import { ManageSessionStorage } from '../core/SessionStorage';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

function TournamentContainer(props) {


    const _history = useNavigate();
    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;
    const [visible, setVisible] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);
    const [addMode, setAddMode] = useState(userData.permission.add);

    const [tournamentList, settournamentList] = useState([]);
    const [completedtournamentList, setCompletedtournamentList] = useState([]);

    const [completed, setCompleted] = useState(false);

    const [selectedData, setSelectedRowData] = useState(null);
    const toast_ = useRef(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };


    useEffect(() => {
        loadtournamentList();
    }, []);


    useEffect(() => {
        loadtournamentList();
    }, [activeIndex]);


    const resetList = () => {
        settournamentList([]);
        setCompletedtournamentList([]);
    }

    const loadNewTournamentPage = () => {
        _history(RENDER_URL.NEW_TOURNAMENT_URL)
    }


    const loadtournamentList = () => {

        resetList();

        let params = {
            active: 1
        }

        dispatch({
            type: START_LOADING,
        });

        if (activeIndex == 0) {
            geTournamentList(params).then((response) => {
                console.log("response", response);
                dispatch({
                    type: STOP_LOADING,
                });
                //adding campaign list 
                settournamentList(response.data.data);
            }).catch((error) => {
                dispatch({
                    type: STOP_LOADING,
                });

                console.log("Error", error);
            })
        } else {

            geTournamentListCompleted(params).then((response) => {
                console.log("response", response);
                dispatch({
                    type: STOP_LOADING,
                });

                setCompletedtournamentList(response.data.data);
            }).catch((error) => {
                dispatch({
                    type: STOP_LOADING,
                });

                console.log("Error", error);
            })
        }
    }

    const onCampaignStatusChange = (rowData, e) => {

        /* dispatch({
            type: START_LOADING,
        });

        let param = {
            "campaign": rowData.id,
            "active": e.target.checked ? 1 : 0
        }

        statusChangeCampaign(JSON.stringify(param)).then((response) => {

            if (response.data.success) {
                dispatch({
                    type: STOP_LOADING,
                });

                //toast_.current.show({ severity: 'success', summary: ~`${response.data.message}`, detail: '', life: 3000 });

                loadCampaignList();
            }

        }).catch((error) => {
            //toast_.current.show({ severity: 'error', summary: ~`${error.message}`, detail: '', life: 3000 });
            dispatch({
                type: STOP_LOADING,
            });
        }) */

    }

    const onDataRowClick = (event) => {
        _history(RENDER_URL.UPDATE_TOURNAMENT_URL, { state: { DATA: event.data } });
    }

    const dateStartPeriodTemplate = (rowData) => {
        let startDate = new Date(rowData.start);
        return <span>{rowData.start ? <Moment format="YYYY-MM-DD hh:mm:ss A">
            {startDate}
        </Moment> : `<div> </div>`}</span>;

    }
    const dateEndPeriodTemplate = (rowData) => {
        let startDate = new Date(rowData.end);
        return <span>{rowData.start ? <Moment format="YYYY-MM-DD hh:mm:ss A">
            {startDate}
        </Moment> : `<div> </div>`}</span>;

    }

    const renderImage = (rowData) => {
        return <React.Fragment>
            <img src={rowData.imageURL} width="50px" height="50px" />
        </React.Fragment>
    }

    const renderDataTableHeader = () => {
        return (
            <div className="flex justify-content-between" style={{ margin: '10px' }}>
                <Button type="button" style={{ visibility: 'hidden' }} icon="pi pi-filter-slash" label="Clear Filters" className="p-button-outlined" />
                <div style={{ float: 'right' }}>

                    {addMode && <Button type="button" style={{ marginLeft: '10px' }} icon="pi pi-user" label="Add New Tournament" className="p-button-outlined" onClick={() => { loadNewTournamentPage() }} />}
                </div>
            </div>
        )
    }

    const activeTemplate = (rowData) => {

        return <React.Fragment>
            <input type="checkbox" checked={rowData.active == 1 ? true : false} onChange={(e) => { onCampaignStatusChange(rowData, e) }} />
        </React.Fragment>

        //return <span>{rowData.reset == 1 ? <span className='text-center'><input type="checkbox" checked onChange={(e) => { onCampaignStatusChange(rowData, e) }} /></span> : <span className='text-center'><input type="checkbox" checked='false' /></span>}</span>;

    }

    const liveTemplate = (rowData) => {

        return <React.Fragment>
            {rowData.test == 1 ? <Tag severity="info" value="Playtest"></Tag> : <Tag severity="warning" value="Live"></Tag>}

        </React.Fragment>

        //return <span>{rowData.reset == 1 ? <span className='text-center'><input type="checkbox" checked onChange={(e) => { onCampaignStatusChange(rowData, e) }} /></span> : <span className='text-center'><input type="checkbox" checked='false' /></span>}</span>;

    }

    const onShowStatitics = (rowData) => {

        _history(RENDER_URL.TOURNAMENT_STAT_URL, { state: { selectedData: rowData } });
    }

    const renderStatisticsButton = (rowData) => {

        return <Button label='Stats' className="p-button-outlined" onClick={() => { onShowStatitics(rowData) }} />

    }



    const hostTemplate = (rowData) => {
        return <React.Fragment>
            {rowData.host == "INTERVERSE" ? <Tag severity="info" value={rowData.host}></Tag> : <Tag severity="warning" value={rowData.host}></Tag>}

        </React.Fragment>

        //return <span>{rowData.reset == 1 ? <span className='text-center'><input type="checkbox" checked onChange={(e) => { onCampaignStatusChange(rowData, e) }} /></span> : <span className='text-center'><input type="checkbox" checked='false' /></span>}</span>;

    }

    return (
        <main id="main">
            <HeaderContainer />

            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">
                    <Toast ref={toast_} position="top-center" />
                    {renderDataTableHeader()}
                    <div className="card">

                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>

                            <TabPanel header="Active" leftIcon="pi pi-fw">
                                <DataTable value={tournamentList} onRowClick={(e) => onDataRowClick(e)} responsiveLayout="scroll">
                                    <Column field="name" header="Title"></Column>
                                    {/* <Column field="description" header="Description"></Column> */}
                                    <Column field="imageURL" header="Image" body={renderImage}></Column>
                                    <Column field="reset" header="Active" body={activeTemplate}></Column>
                                    <Column field="test" header="Server" body={liveTemplate}></Column>
                                    <Column field="start" header="Start" body={dateStartPeriodTemplate}></Column>
                                    <Column field="end" header="End" body={dateEndPeriodTemplate} ></Column>
                                    <Column field="maxPlayers" header="Max Players Per Server"></Column>
                                    <Column field="totalParticipants" header="Total Participants Per Tournament"></Column>
                                    <Column field="host" header="Hosted By" body={hostTemplate}></Column>
                                </DataTable>
                            </TabPanel>

                            <TabPanel header="Completed" leftIcon="pi pi-fw">
                                <DataTable value={completedtournamentList} responsiveLayout="scroll">
                                    <Column field="name" header="Title"></Column>
                                    {/* <Column field="description" header="Description"></Column> */}
                                    <Column field="imageURL" header="Image" body={renderImage}></Column>
                                    <Column field="reset" header="Active" body={activeTemplate}></Column>
                                    <Column field="test" header="Server" body={liveTemplate}></Column>
                                    <Column field="start" header="Start" body={dateStartPeriodTemplate}></Column>
                                    <Column field="end" header="End" body={dateEndPeriodTemplate} ></Column>
                                    <Column field="maxPlayers" header="Max Players Per Server"></Column>
                                    <Column field="totalParticipants" header="Total Participants Per Tournament"></Column>
                                    <Column header="Statistics" body={renderStatisticsButton}></Column>
                                </DataTable>
                            </TabPanel>

                        </TabView>
                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>)

}



export default TournamentContainer;