import { mapCommunityColorCode } from "../common/CommunityColor";
import {
    LOGIN_SUCCESS,
    LOGOUT,
    APP_TOKEN,
    USER_TOKEN,
    SET_COLLECTION,
    SET_ASSET,
    SET_ITEM,SET_MAPS,SET_GUNS,
    ACDMY_COLLECTION,
    ASSET_COLLECTION, ITEM_COLLECTION,DR_MAPS_COLLECTION,DR_GUNS_COLLECTION,
    USER_DATA
} from "../common/Constants";
import { ManageLocalStorage } from "../core/LocalStorage"
import { ManageSessionStorage } from "../core/SessionStorage"




let applicationToken = ManageLocalStorage.get(APP_TOKEN) ? ManageLocalStorage.get(APP_TOKEN) : null
export const loginInitialState = {
    applicationToken: applicationToken,
    ACDMY_COLLECTION: ManageLocalStorage.get(ACDMY_COLLECTION) ? JSON.parse(ManageLocalStorage.get(ACDMY_COLLECTION)) : [{
        "code": "DAA",
        "name": "Degen Apes"
    }],
    ASSET_COLLECTION: ManageLocalStorage.get(ASSET_COLLECTION) ? JSON.parse(ManageLocalStorage.get(ASSET_COLLECTION)) : [],
    ITEM_COLLECTION: ManageLocalStorage.get(ITEM_COLLECTION) ? JSON.parse(ManageLocalStorage.get(ITEM_COLLECTION)) : [],
    DR_MAPS_COLLECTION: ManageLocalStorage.get(DR_MAPS_COLLECTION) ? JSON.parse(ManageLocalStorage.get(DR_MAPS_COLLECTION)) : [],
    DR_GUNS_COLLECTION: ManageLocalStorage.get(DR_GUNS_COLLECTION) ? JSON.parse(ManageLocalStorage.get(DR_GUNS_COLLECTION)) : [],
    ITEM_COLLECTION: ManageLocalStorage.get(ITEM_COLLECTION) ? JSON.parse(ManageLocalStorage.get(ITEM_COLLECTION)) : [],
    isLoggedIn: ManageLocalStorage.get(USER_DATA)
        ? true
        : false,
    userData: ManageLocalStorage.get(USER_DATA) ? JSON.parse(ManageLocalStorage.get(USER_DATA)) : undefined
};

mapCommunityColorCode(loginInitialState.ACDMY_COLLECTION);

export const LoginReducer = (state, action) => {

    if (typeof state === 'undefined') {
        return loginInitialState
    }

    
    switch (action.type) {
    
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                userData: action.payload
            };

        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                userData: null,
            };
        case SET_COLLECTION:
            console.log(">>>>>COLLECT",action.payload)
            return {
                ...state,
                ACDMY_COLLECTION: action.payload
            };
        case SET_ASSET:
            return {
                ...state,
                ASSET_COLLECTION: action.payload
            };
        case SET_ITEM:
            console.log(">>>> ITEM",action.payload);
            return {
                ...state,
                ITEM_COLLECTION: action.payload
            };
        case SET_MAPS:
            return {
                ...state,
                DR_MAPS_COLLECTION: action.payload
            };
        case SET_GUNS:
            return {
                ...state,
                DR_GUNS_COLLECTION: action.payload
            };
        default:
            return state;
    }
};