import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import moment from 'moment';
import { getCampaignList, getCampaignListCompleted, statusChangeCampaign, stopCampaign } from './CampaignService';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING, ITEM_COLLECTION, ASSET_COLLECTION, SET_ASSET, SET_ITEM } from '../common/Constants';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { ManageLocalStorage } from '../core/LocalStorage';
import { ManageSessionStorage } from '../core/SessionStorage';
import { FilterMatchMode, FilterOperator } from 'primereact/api';


function CampaignContainer(props) {

    const _history = useNavigate();
    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;
    const [visible, setVisible] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);
    const [addMode, setAddMode] = useState(userData.permission.add);

    const [campaignList, setCampaignList] = useState([]);
    const [completedCampagnList, setCompletedCampaignList] = useState([]);

    const [completed, setCompleted] = useState(false);

    const [selectedData, setSelectedRowData] = useState(null);
    const toast_ = useRef(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };


    useEffect(() => {
        loadCampaignList();
    }, []);


    useEffect(() => {
        loadCampaignList();
    }, [activeIndex]);


    const resetList = () => {
        setCampaignList([]);
        setCompletedCampaignList([]);
    }

    const loadCampaignList = () => {

        resetList();

        let params = {
            active: 1
        }

        dispatch({
            type: START_LOADING,
        });

        if (activeIndex == 0) {
            getCampaignList(params).then((response) => {
                console.log("response", response);
                dispatch({
                    type: STOP_LOADING,
                });

                let collection = response.data.collections
                let assets = response.data.assets
                let items = response.data.items
                if (collection != undefined) {
                    ManageLocalStorage.set(ACDMY_COLLECTION, collection)
                    dispatch({
                        type: SET_COLLECTION,
                        payload: collection,
                    });
                }

                if (assets != undefined) {
                    ManageLocalStorage.set(ASSET_COLLECTION, assets)
                    dispatch({
                        type: SET_ASSET,
                        payload: assets,
                    });
                }

                if (items != undefined) {
                    ManageLocalStorage.set(ITEM_COLLECTION, items)
                    dispatch({
                        type: SET_ITEM,
                        payload: items,
                    });
                }
                //adding campaign list 
                setCampaignList(response.data.data);
            }).catch((error) => {
                dispatch({
                    type: STOP_LOADING,
                });

                console.log("Error", error);
            })
        } else {

            getCampaignListCompleted(params).then((response) => {
                console.log("response", response);
                dispatch({
                    type: STOP_LOADING,
                });

                setCompletedCampaignList(response.data.data);
            }).catch((error) => {
                dispatch({
                    type: STOP_LOADING,
                });

                console.log("Error", error);
            })
        }



    }


    const loadNewCampaignpage = () => {
        _history(RENDER_URL.NEW_CAMP_URL)
    }

    const items = [
        { label: 'Active', icon: 'pi pi-fw pi-home' },
        { label: 'Completed', icon: 'pi pi-fw pi-pencil' }
    ];

    const dateStartPeriodTemplate = (rowData) => {
        let startDate = new Date(rowData.start * 1000);
        return <span>{rowData.start ? <Moment format="YYYY/MM/DD">
            {startDate}
        </Moment> : `<div> </div>`}</span>;

    }
    const dateEndPeriodTemplate = (rowData) => {
        let startDate = new Date(rowData.end * 1000);
        return <span>{rowData.start ? <Moment format="YYYY/MM/DD">
            {startDate}
        </Moment> : `<div> </div>`}</span>;

    }


    const changeTab = (index) => {
        if (index == 0) {
            setCompleted(false);
            setActiveIndex(index)
            setCampaignList([])
            setCompletedCampaignList([]);

        }
        else if (index == 1) {
            setCompleted(true);
            setActiveIndex(index)
            setCampaignList([])
            setCompletedCampaignList([]);
        }
        dispatch({
            type: START_LOADING,
        });
    }


    const onCampaignStatusChange = (rowData, e) => {

        dispatch({
            type: START_LOADING,
        });

        let param = {
            "campaign": rowData.id,
            "active": e.target.checked ? 1 : 0
        }

        statusChangeCampaign(JSON.stringify(param)).then((response) => {

            if (response.data.success) {
                dispatch({
                    type: STOP_LOADING,
                });

                //toast_.current.show({ severity: 'success', summary: ~`${response.data.message}`, detail: '', life: 3000 });

                loadCampaignList();
            }

        }).catch((error) => {
            //toast_.current.show({ severity: 'error', summary: ~`${error.message}`, detail: '', life: 3000 });
            dispatch({
                type: STOP_LOADING,
            });
        })

    }

    const activeTemplate = (rowData) => {

        return <React.Fragment>
            <input type="checkbox" checked={rowData.active == 1 ? true : false} onChange={(e) => { onCampaignStatusChange(rowData, e) }} />
        </React.Fragment>

        //return <span>{rowData.reset == 1 ? <span className='text-center'><input type="checkbox" checked onChange={(e) => { onCampaignStatusChange(rowData, e) }} /></span> : <span className='text-center'><input type="checkbox" checked='false' /></span>}</span>;

    }


    const renderImage = (rowData) => {
        return <React.Fragment>
            <img src={rowData.imageURL} width="50px" height="50px" />
        </React.Fragment>
    }

    const liveTemplate = (rowData) => {

        return <React.Fragment>
            {rowData.test == 1 ? <Tag severity="info" value="Playtest"></Tag> : <Tag severity="warning" value="Live"></Tag>}

        </React.Fragment>

        //return <span>{rowData.reset == 1 ? <span className='text-center'><input type="checkbox" checked onChange={(e) => { onCampaignStatusChange(rowData, e) }} /></span> : <span className='text-center'><input type="checkbox" checked='false' /></span>}</span>;

    }

    const renderDataTableHeader = () => {
        return (
            <div className="flex justify-content-between" style={{ margin: '10px' }}>
                <Button type="button" style={{ visibility: 'hidden' }} icon="pi pi-filter-slash" label="Clear Filters" className="p-button-outlined" />
                <div style={{ float: 'right' }}>
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                    {addMode && <Button type="button" style={{ marginLeft: '10px' }} icon="pi pi-user" label="Add New Campaign" className="p-button-outlined" onClick={() => loadNewCampaignpage()} />}
                </div>
            </div>
        )
    }


    const getDate = (value) => {

        return moment(value * 1000).format("YYYY/MM/DD")

    }

    const onDataRowClick = (event) => {
        setVisible(true);
        setSelectedRowData(event.data);
    }

    const onShowStatitics = (rowData) => {
        console.log(rowData);
        _history(RENDER_URL.CAMP_STAT_URL, { state: { selectedData: rowData } });
    }

    const onStopCampaign = (rowData) => {

        console.log(rowData);

        dispatch({
            type: START_LOADING,
        });

        let param = {
            "campaign": rowData.id,
            "active": rowData.active,
            "stop": 1
        }

        stopCampaign(JSON.stringify(param)).then((response) => {

            if (response.data.success) {
                dispatch({
                    type: STOP_LOADING,
                });

                //toast_.current.show({ severity: 'success', summary: ~`${response.data.message}`, detail: '', life: 3000 });
                loadCampaignList();
            }

        }).catch((error) => {
            //toast_.current.show({ severity: 'error', summary: ~`${error.message}`, detail: '', life: 3000 });
            dispatch({
                type: STOP_LOADING,
            });
        })


    }

    const renderStatisticsButton = (rowData) => {

        return <Button label='Show' className="p-button-outlined" onClick={() => { onShowStatitics(rowData) }} />

    }

    const renderStopButton = (rowData) => {

        return <Button label='Stop' className="p-button-outlined" style={{ border: 'solid 1px red' }} onClick={() => { onStopCampaign(rowData) }} />

    }

    

    return (
        <main id="main">
            <HeaderContainer />

            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">
                    <Toast ref={toast_} position="top-center" />
                    {renderDataTableHeader()}
                    <div className="card">

                        {selectedData && <Dialog header="Campaign Details Page" visible={visible} style={{ width: '85vw' }} onHide={() => setVisible(false)}>
                            <p className="m-0">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group">
                                                <label>Title</label>
                                                <input type="text" className="form-control" readOnly placeholder="Enter title" value={selectedData.name} />
                                            </div>
                                        </div>

                                        <div className='col-sm-6'>
                                            <div className="form-group">
                                                <label>SubTitle</label>
                                                <input type="text" className="form-control" readOnly placeholder="Enter subtitle" value={selectedData.subtitle} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className="form-group">
                                                <label>Description</label>
                                                <InputTextarea readOnly className="form-control" placeholder="Enter description" value={selectedData.description} rows={5} cols={30} />
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form-group">
                                                <label>Image</label>
                                                <img src={selectedData.imageURL} style={{ marginLeft: '10px' }} width='150px' height='150px' placeholder="../assets/img/image_placeholde.png" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group">
                                                <label>More Info</label>
                                                <input type="text" readOnly className="form-control" placeholder="Enter more info" value={selectedData.moreInfoURL} />
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="form-group">
                                                <label>Start</label>
                                                <input type="text" readOnly className="form-control" placeholder="Enter start date" value={getDate(selectedData.start)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <div className="form-group">
                                                <label>Ends</label>
                                                <input type="text" readOnly className="form-control" placeholder="Enter end date" value={getDate(selectedData.end)} />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <div className="form-group">
                                                <label>Items per day</label>
                                                <input type="text" readOnly className="form-control" placeholder="Enter items per day" value={selectedData.itpd} />
                                            </div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className="form-group">
                                                <label>Items Per Game</label>
                                                <input type="text" readOnly className="form-control" placeholder="Item Per Game" value={selectedData.itpg} />
                                            </div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className="form-group">
                                                <label>Time of spawn (in minutes)</label>
                                                <input type="text" readOnly className="form-control" placeholder="Enter time in minutes" value={selectedData.tbsp} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>

                                        <div className='col-sm-4'>
                                            <div className="form-group">
                                                <label>Participants</label>
                                                <br></br>
                                                <MultiSelect value={selectedData.collections} filter options={selectedData.collections}
                                                    placeholder="Select Participants" maxSelectedLabels={3} className="w-full md:w-20rem" />

                                            </div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className="form-group">
                                                <label>Guns</label>
                                                <br></br>
                                                <MultiSelect value={selectedData.items} filter options={selectedData.items}
                                                    placeholder="Select Participants" maxSelectedLabels={3} className="w-full md:w-20rem" />

                                            </div>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className="form-group">
                                                <label>Items</label>
                                                <br></br>
                                                <MultiSelect value={selectedData.assets} filter options={selectedData.assets}
                                                    placeholder="Select Participants" maxSelectedLabels={3} className="w-full md:w-20rem" />
                                            </div>
                                        </div>
                                    </div>





                                </div>
                            </p>
                        </Dialog>}

                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>

                            <TabPanel header="Active" leftIcon="pi pi-fw">
                                <DataTable value={campaignList} filters={filters}  globalFilterFields={['name']} onRowClick={(e) => onDataRowClick(e)} responsiveLayout="scroll">
                                    <Column field="name" header="Title"></Column>
                                    <Column field="subtitle" header="SubTitle"></Column>
                                    {/* <Column field="start" header="Start" body={dateStartPeriodTemplate}></Column> */}
                                    <Column field="end" header="End" body={dateEndPeriodTemplate} ></Column>
                                    <Column field="reset" header="Active" body={activeTemplate}></Column>
                                    <Column field="imageURL" header="Image" body={renderImage}></Column>
                                    <Column field="test" header="Playtest" body={liveTemplate}></Column>
                                    <Column header="Statistics" body={renderStatisticsButton}></Column>
                                    <Column header="Stop" body={renderStopButton}></Column>
                                </DataTable>
                            </TabPanel>

                            <TabPanel header="Completed" leftIcon="pi pi-fw">
                                <DataTable value={completedCampagnList} filters={filters}  globalFilterFields={['name']} onRowClick={(e) => onDataRowClick(e)} responsiveLayout="scroll">
                                    <Column field="name" header="Name"></Column>
                                    <Column field="start" header="Start" body={dateStartPeriodTemplate}></Column>
                                    <Column field="end" header="End" body={dateEndPeriodTemplate} ></Column>
                                    <Column field="reset" header="Active" body={activeTemplate}></Column>
                                    <Column field="imageURL" header="Image" body={renderImage}></Column>
                                    <Column field="test" header="Playtest" body={liveTemplate}></Column>
                                    <Column header="Statistics" body={renderStatisticsButton}></Column>
                                </DataTable>
                            </TabPanel>

                        </TabView>

                        {/*   <DataTable value={campaignList} header={renderDataTableHeader} responsiveLayout="scroll">
                            <Column field="name" header="Name"></Column>
                            <Column field="start" header="Start" body={dateStartPeriodTemplate}></Column>
                            <Column field="end" header="End" body={dateEndPeriodTemplate} ></Column>
                            <Column field="reset" header="Active" body={activeTemplate}></Column>
                        </DataTable> */}

                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );
}

export default CampaignContainer;