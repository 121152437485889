import React, { Component } from 'react';


function FooterContainer(props) {

    return (
        <footer id="footer">
            <div className="container">
                <div className="me-md-auto text-center">
                    <div className="copyright">
                        &copy; Copyright <strong><span>interverse</span></strong>. All Rights Reserved
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default FooterContainer;