import { httpCall, httpFormCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const fetchDetailApi = params => {
    return httpFormCall({
        url: API_URL.AVATAR_FETCH_EXPAND,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const addAvatarApi = params => {
    return httpCall({
        url: API_URL.ADD_AVATAR,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

