import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import ContentEditable from 'react-contenteditable'
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import './dialog.css'
import { fetchDetailApi, addAvatarApi } from './UserService';
import { Toast } from 'primereact/toast';
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { InputSwitch } from 'primereact/inputswitch';
import { RENDER_URL, START_LOADING, STOP_LOADING, ACDMY_COLLECTION, SET_RUMBLE_LOCAL } from '../common/Constants';
import {getColorCodeForCommunity} from '../common/CommunityColor'
function UserContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const context = React.useContext(GlobalContext);
    const _history = useNavigate();

    let localStoredRumbleList = context.common.localRumbleList;
    const userData = context.login.userData;

    const [createdRumbleUserList, setcreatedRumbleUserList] = useState([]);
    const [singleAvatarCharactor, setAvatarCharactor] = useState();
    const [token, setToken] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [selectedAvatarData, setSelectedRowData] = useState(null);
    const [selectedCommunity, setSelectedCommunity] = useState(null);
    const [editImage, setChangeImageMode] = useState(false);
    const [attributeEditMode, setAttributeEditMode] = useState(true);
    const [editMode, setEditMode] = useState(userData.permission.edit ? userData.permission.edit : false);
    const [addMode, setAddMode] = useState(userData.permission.add);
    const [value, setValue] = useState('');
    const [position, setPosition] = useState('center');
    const [communityErrorMsg, setCommunityError] = useState('');
    const [tokenErrorMsg, setTokenError] = useState('');
    const [displayBasic, setDisplayBasic] = useState(false);
    const [checked, setChecked] = useState(true);
    const toast_ = useRef(null);


    const columns = [
        { field: 'username', header: 'Email' },
        { field: 'community', header: 'Community' },
        { field: 'AvatarID', header: 'Avatar ID' },
        { field: 'name', header: 'Avatar Name' },
    ];

    const _communityList = context.login.ACDMY_COLLECTION;

    useEffect(() => {
        setSelectedCommunity(_communityList[0].code)
    }, [])


    useEffect(() => {
        if (!addMode) {
            _history(RENDER_URL.HOME_URL);
        }
    }, [addMode])

    const validateFetch = () => {

        console.log(selectedCommunity);
        if (selectedCommunity == null || selectedCommunity == undefined || selectedCommunity == "") {
            setCommunityError('Please choose a community');
        } else if (token == "") {
            setTokenError("Please enter token");
        }
        else {
            setCommunityError("");
            setTokenError("");
            return true;
        }

    }

    const fetchAvatarDetails = () => {

        if (validateFetch()) {
            dispatch({
                type: START_LOADING,
            });

            let params = {
                community: selectedCommunity,
                token: token
            }

            let v_community = params.community.toLowerCase();
            let v_idToken = params.token;
            let checkingScript = `${v_community}_${v_idToken}`;

            let isPresent = localStoredRumbleList.filter((entry) => {
                console.log(entry.AvatarID);
                if (entry.AvatarID == checkingScript) {
                    return true;
                } else {
                    return false;
                }
            })

            if (isPresent.length > 0) {
                dispatch({
                    type: STOP_LOADING,
                });

                onHide('displayBasic')
                toast_.current.show({ severity: 'error', summary: 'Avatar Already Present', detail: '', life: 3000 });
            }
            else {
                console.log("NEW AVATAR LOADING FROM API");
                fetchDetailApi(params).then((response) => {

                    if (response.data.success) {
                        let avatar = response.data.avatar;
                        avatar.active = 1;
                        setAvatarCharactor(avatar)
                        setUsername(avatar.username);
                        setPassword(avatar.password);
                    }
                    else{
                        onHide('displayBasic');
                        toast_.current.show({ severity: 'error', summary: response.data.message, detail: '', life: 3000 });
                    }
                    dispatch({
                        type: STOP_LOADING,
                    });

                }).catch((error) => {
                    console.log("Error of fetch details", error);
                })
            }
        }
    }


    const onSave = () => {
        dispatch({
            type: START_LOADING,
        });

        let params = {
            rows: createdRumbleUserList
        }
        addAvatarApi(params).then((response) => {

            if (response.data.success) {

                if (response.data.failed.length > 0) {

                    let failedUser = createdRumbleUserList.map(e => {
                        if (response.data.failed.includes(e.username)) {
                            return e;
                        }
                    })

                    setcreatedRumbleUserList(failedUser);
                    toast_.current.show({ severity: 'error', summary: `Couldn't add these accounts`, detail: '', life: 3000 });
                    console.log("FAILED ACCOUNTS", failedUser);

                }
                else {
                    toast_.current.show({ severity: 'success', summary: 'Successfully Added', detail: '', life: 3000 });
                    setcreatedRumbleUserList([]);
                    dispatch({
                        type: SET_RUMBLE_LOCAL,
                        payload: [],
                    });
                    setTimeout(() => {
                        _history(RENDER_URL.HOME_URL, { state: { refreshList: true } });
                    }, 1000);
                }
            } else {

            }

            dispatch({
                type: STOP_LOADING,
            });

        }).then((error) => {
            console.log("error", error);
        })
    }

    const onUserNameChange = (value) => {
        let avatar = singleAvatarCharactor;
        avatar['username'] = value;
        setUsername(value);
        setAvatarCharactor(avatar)
    }
    const onPasswordChange = (value) => {
        let avatar = singleAvatarCharactor;
        avatar['password'] = value;
        setPassword(value);
        setAvatarCharactor(avatar)
    }

    const onDiscardList = () => {
        setcreatedRumbleUserList([]);
        _history(RENDER_URL.HOME_URL);
    }

    const cellEditor = (options) => {
        if (options.field === 'price')
            return priceEditor(options);
        else
            return textEditor(options);
    }

    const communityOptionTemplate = (option) => {
        return (
            <span className={`communitybadge ${option.code}`} style={{backgroundColor: `hsl(${getColorCodeForCommunity(option.code)}, 80%, 50%)`, color: "white"}} >{option.name}</span>
        );
    }

    const priceEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="USD" locale="en-US" />
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;

        switch (field) {
            default:
                if (newValue.trim().length > 0) {
                    rowData[field] = newValue;
                }
                else
                    event.preventDefault();
                break;
        }

    }
    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        resetValues();
        dialogFuncMap[`${name}`](false);
    }

    const onCommunityChange = (e) => {
        console.log(e);
        setSelectedCommunity(e.value);
    }

    const onAttributeEditChanges = (e, key) => {
        singleAvatarCharactor.metadata[key] = e.target.value;
        setAvatarCharactor(singleAvatarCharactor);
    }

    const resetValues = () => {
        setAvatarCharactor(null);
        //setSelectedCommunity(null);
        setChecked(true);
        setToken("");
        setUsername("");
        setPassword("")
    }

    const onDataRowClick = (event) => {
        if (!editMode) {
            setAvatarCharactor(event.data);
            setUsername(event.data.username);
            setPassword(event.data.password);
            onClick('displayBasic')
        }
    }

    const onAddToList = () => {

        let alreadyPresent = false;
        let alreadyEditDataPresent = createdRumbleUserList.map(item => {
            if (item.AvatarID == singleAvatarCharactor.AvatarID) {
                alreadyPresent = true;
                item = singleAvatarCharactor;
                return item;
            }
            else {
                return item;
            }
        })

        let tempList = [];
        if (alreadyEditDataPresent.length > 0 && alreadyPresent) {
            tempList = alreadyEditDataPresent;
        }
        else {
            console.log("else");
            tempList = [...createdRumbleUserList, singleAvatarCharactor]
        }

        setcreatedRumbleUserList(tempList);
        resetValues();
        onHide('displayBasic')
    }

    const onActiveStatusChange = (e, key) => {
        singleAvatarCharactor[key] = e.value ? 1 : 0;
        setChecked(e.value ? true : false)
        setAvatarCharactor(singleAvatarCharactor)
    }


    const renderAttributeImg = (imageLink) => {

        imageLink = imageLink ? imageLink : "https://icon-library.com/images/ape-icon/ape-icon-14.jpg";
        if (editImage && token.includes('guest')) {
            return <ContentEditable className='form-control' html={imageLink} onBlur={handleBlur} onChange={(e) => { onImageEditChanges(e, 'image') }} />
        }
        else {
            const imgTag = token.includes('guest') ? `<div><img src='${imageLink}' width='280px'/>double click to change</div>` : `<img src='${imageLink}' width='280px'/>`

            return <ContentEditable html={imgTag} />
        }
    }

    const onImageEditChanges = (e, key) => {
        singleAvatarCharactor[key] = e.target.value;
        setAvatarCharactor(singleAvatarCharactor)
    }

    const changeImage = () => {
        setChangeImageMode(!editImage)
    }

    const handleBlur = () => {
        changeImage();
    };


    const renderFooter = (name) => {
        if (name == "displayBasic") {
            return (
                <div>
                    <Button label="Fetch Details" icon="pi pi-check" className="p-button-success" onClick={() => fetchAvatarDetails()} autoFocus />
                </div>
            );
        } else {
            return (
                <div>
                    <Button label="Add to List" icon="pi pi-check" className="p-button-warning" onClick={() => onAddToList()} autoFocus />
                </div>
            );
        }

    }

    const renderAttribute = () => {
        if (singleAvatarCharactor) {
            if (singleAvatarCharactor.metadata) {
                return (
                    Object.entries(singleAvatarCharactor.metadata).map(([index, object]) => {
                        return <div key={index} className="attribute" >
                            {index}
                            <span></span>
                            <ContentEditable
                                html={object}
                                disabled={attributeEditMode ? false : true}
                                onChange={(e) => { onAttributeEditChanges(e, index) }}
                                tagName='article'
                            />
                        </div>
                    })
                )
            }
        }
        else {
            return <></>
        }

    }

    return (
        <main id="main">
            <HeaderContainer />
            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">
                    <Toast ref={toast_} position="top-center" />
                    <div className="row">
                        <div>
                            <Button label='Add New Avatar' icon="pi pi-sign-in" className="p-button-secondary" onClick={() => onClick('displayBasic')} />
                            {createdRumbleUserList.length > 0 && <div className='row d-flex' style={{ float: 'right' }}>
                                <div >
                                    <Button label='Discard' className='p-button-secondary mr-4' onClick={() => { onDiscardList() }}></Button>
                                    <Button label='Save' className='p-button-success' onClick={() => { onSave() }}></Button>
                                </div>
                            </div>}
                        </div>

                    </div>
                    <Dialog header="Add User" visible={displayBasic} style={{ width: '65vw' }} footer={renderFooter(singleAvatarCharactor ? 'Add' : 'displayBasic')} onHide={() => onHide('displayBasic')}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-sm-12">
                                    <div>
                                        <table className="table table-hover mx-auto">
                                            <tbody>
                                                <tr>
                                                    <th className="align-middle">
                                                        Community
                                                    </th>
                                                    <td className="align-middle">
                                                        <Dropdown className='form-control' disabled={singleAvatarCharactor ? true : false} value={selectedCommunity} options={_communityList} itemTemplate={communityOptionTemplate} onChange={onCommunityChange} optionLabel="name" optionValue='code' placeholder="Select Community" />
                                                        <small id="username-help" className="p-error block">{communityErrorMsg}</small>
                                                    </td>
                                                    <th className="align-middle">
                                                        Token
                                                    </th>
                                                    <td className="align-middle">
                                                        <InputText className='form-control' value={token} onChange={(e) => setToken(e.target.value)} />
                                                        <small id="username-help" className="p-error block">{tokenErrorMsg}</small>
                                                    </td>
                                                </tr>
                                                {singleAvatarCharactor &&
                                                    <>
                                                        <tr>
                                                            <th className="align-middle">
                                                                Username
                                                            </th>
                                                            <td className="align-middle">
                                                                <InputText className='form-control' value={username} onChange={(e) => onUserNameChange(e.target.value)} />
                                                            </td>
                                                            <th className="align-middle">
                                                                Password
                                                            </th>
                                                            <td className="align-middle">
                                                                <InputText id='password_full' value={password} onChange={(e) => onPasswordChange(e.target.value)} />
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <th>Active</th>
                                                            <td><InputSwitch className='_button' checked={checked} onChange={(e) => onActiveStatusChange(e, 'active')} /></td>
                                                        </tr>
                                                    </>

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            {singleAvatarCharactor && <div className="row">
                                <div className="col-sm-4">
                                    {/* <div>
                                        <div className='d-flex justify-content-center' onDoubleClick={() => { changeImage() }}>
                                            {renderAttributeImg(singleAvatarCharactor.image)}
                                        </div>
                                    </div> */}
                                    <img src={singleAvatarCharactor.image ? singleAvatarCharactor.image : "https://icon-library.com/images/ape-icon/ape-icon-14.jpg"} width='280px' />
                                </div>
                                {singleAvatarCharactor.metadata ? <div className="col-sm-8">
                                    <div className='attributes'>
                                        {renderAttribute()}
                                    </div>
                                </div> : <div className="col-sm-8"><span className='p-error block'><b>No Traits Found</b></span></div>}
                            </div>}
                        </div>
                    </Dialog>
                    <br></br>
                    <div className="card">
                        <div className="card-fluid">
                            <div className="row">
                                <DataTable value={createdRumbleUserList} onRowClick={(e) => onDataRowClick(e)} editMode="cell" className="editable-cells-table" responsiveLayout="scroll" emptyMessage="Add new avatar">
                                    {
                                        columns.map(({ field, header }) => {
                                            return <Column key={field} field={field} header={header} style={{ width: '25%' }} />
                                        })


                                        /* ROW WITH EDIT METHOD HIDING FOR NOW 
                                          <Column key={field} field={field} header={header} style={{ width: '25%' }}
                                                  editor={(field == "AvatarID") ? "" : (options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} /> */
                                    }
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>
    );
}

export default UserContainer;