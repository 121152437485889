import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import moment from 'moment';
import { gettournamentList, savetournament, updateTournament, saveImagetournament } from './TournamentService';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { STOP_LOADING, START_LOADING, RENDER_URL, DR_ALL } from '../common/Constants';
import { Toast } from 'primereact/toast';

import { InputTextarea } from 'primereact/inputtextarea';

import { FileUpload } from 'primereact/fileupload';
import { InputSwitch } from "primereact/inputswitch";

import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';

import { Tag } from 'primereact/tag';



function TournamentUpdate(props) {


    const _history = useNavigate();
    const location = useLocation();

    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;

    const data = location.state.DATA;

    const [selectedFile, setSelectedFile] = React.useState(null);

    const [tournament_id, setTournamentID] = useState(data.id);

    const [tournamentName, settournamentName] = useState("");
    const [tournamentNameError, setErrortournamentName] = useState("");
    const [tournamentTitle, settournamentTitle] = useState(data.name);
    const [tournamentTitleError, setErrortournamentTitle] = useState("");

    const [tournamentMaxPlayers, settournamentMaxPlayers] = useState(data.maxPlayers);
    const [tournamentMaxPlayersError, setErrortournamentMaxPlayer] = useState("");

    const [tournamentTotalPlayers, settournamentTotalPlayers] = useState(data.totalParticipants);
    const [tournamentTotalPlayersError, setErrortournamentTotalPlayer] = useState("");

    const [tournamentSubTitle, settournamentSubTitle] = useState("");
    const [tournamentSubTitleError, setErrortournamentSubTitle] = useState("");

    const [tournamentDesc, settournamentDesc] = useState(data.description);
    const [tournamentDescError, setErrortournamentDesc] = useState("");

    const [tournamentPassword, settournamentPassword] = useState(data.password);
    const [tournamentPasswordError, setErrortournamentPassword] = useState("");


    let startDate = moment(data.start).format('YYYY-MM-DD hh:mm:ss A');

    const [tournamentStart, settournamentStart] = useState(moment.utc(startDate).toISOString().slice(0, 16));
    const [tournamentStartError, setErrortournamentStart] = useState("");

    let endDate = moment(data.end).format('YYYY-MM-DD hh:mm:ss A');
    const [tournamentEnds, settournamentEnd] = useState(moment.utc(endDate).toISOString().slice(0, 16));
    const [tournamentEndError, setErrortournamentEnd] = useState("");

    const [imageURL, setImageUrl] = useState(data.imageURL);
    const [isImageChanged, setImageChanged] = useState(false);

    const toast_ = useRef(null);


    const cities = [
        { name: 'Interverse', code: 'Interverse' },
        { name: 'Fractal', code: 'Fractal' },

    ];

    const MapListToElements = (list, elements)=> {
        if (elements.length === 0) {
            return null;
        }
        if (elements.length === 1 && elements[0] === DR_ALL) {
            return list;
        }
        return list.filter((s)=>{
            return elements.includes(s.symbol);
        })
        //  elements;
    }
    const [selectedMaps, setSelectedMaps] = useState(MapListToElements(context.login.DR_MAPS_COLLECTION,data.maps));
    const [selectedGuns, setSelectedGuns] = useState(MapListToElements(context.login.DR_GUNS_COLLECTION,data.guns));
    const [mapsError, setMapsError] = useState("");
    const [gunsError, setGunsError] = useState("");
    const [mapsList, setMapsList] = useState(context.login.DR_MAPS_COLLECTION)
    const [gunsList, setGunsList] = useState(context.login.DR_GUNS_COLLECTION)
    const [selectedHost, setSelectedHost] = useState(cities.find((e) => e.code.toLocaleUpperCase() == data.host?.toLocaleUpperCase()));
    const [selectedHostError, setSelectedHostError] = useState("");


    const [collectionList, setCollectionList] = useState(context.login.ACDMY_COLLECTION)
    const [assetsList, setAssetList] = useState(context.login.ASSET_COLLECTION)
    const [itemsList, setItemsList] = useState(context.login.ITEM_COLLECTION)
    const [isActive, setActiveStatus] = useState((data.active == 1) ? true : false);
    const [isTest, setRareStatus] = useState((data.test == 1) ? true : false);
    const [base64Image, setBase64Image] = useState("");

    const clearValidation = () => {

        setErrortournamentName("");
        setErrortournamentTitle("")
        setErrortournamentName("")
        setErrortournamentSubTitle("")
        setErrortournamentDesc("")
        setErrortournamentStart("");
        setErrortournamentEnd("");
        setErrortournamentPassword("");
        setSelectedHostError("");
    }

    const validation = () => {

        clearValidation()
        if (tournamentTitle == "") {
            setErrortournamentTitle("Enter Title");
            return false;
        }
        else if (tournamentMaxPlayers == "") {
            setErrortournamentMaxPlayer("Enter Max Players");
            return false;
        }
        else if (tournamentMaxPlayers <= 0) {
            setErrortournamentMaxPlayer("Enter Valid Max Players")
            return false;
        }
        else if (tournamentTotalPlayers == "") {
            setErrortournamentTotalPlayer("Enter Total Players");
            return false;
        }
        else if (tournamentTotalPlayers <= 0) {
            setErrortournamentTotalPlayer("Enter Valid Total Players");
            return false;
        }

        else if (tournamentDesc == "") {
            setErrortournamentDesc("Enter Description");
            return false;
        }

        else if (tournamentPassword == "") {
            setErrortournamentPassword("Enter Password");
            return false;
        }

        else if (tournamentStart == "") {
            setErrortournamentStart("Select Start Date");
            return false;
        }
        else if (selectedHost == null) {
            setSelectedHostError("Choose Host");
            return false;
        }
        else if (selectedMaps == null) {
            setGunsError("Select allowed maps");
            return false;
        }
        else if (selectedGuns == null) {
            setGunsError("Select allowed guns");
            return false;
        }
        /* else if (tournamentEnds == "") {
            setErrortournamentEnd("Select End Date");
            return false;
        } */
        else {
            return true
        }
    }


    const manageUpload = async (event) => {
        setImageChanged(true);
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setBase64Image(base64);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handleSubmit = async (event) => {

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("tournament_id", "32");
        try {

            saveImagetournament(formData).then((response) => {

                if (response.data.success) {

                    console.log(response.data);
                }
                else {
                    toast_.current.show({ severity: 'error', summary: ~`${response.data.error}`, detail: '', life: 3000 });
                }

                dispatch({
                    type: STOP_LOADING,
                });

            }).catch((error) => {
                console.log("Error", error);

                dispatch({
                    type: STOP_LOADING,
                });
            })

        } catch (error) {
            console.log(error)
        }
    }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
        /* setTimeout(() => {
            console.log("calls")
            handleSubmit();
        }, 2000); */
    }


    const updateImage = (_tournamentID) => {

        dispatch({
            type: START_LOADING,
        });

        const formData = new FormData();
        formData.append("image", base64Image);
        console.log(base64Image);
        formData.append("tournament_id", _tournamentID);
        try {

            saveImagetournament(formData).then((response) => {
                try {
                    if (response.data.success) {

                        console.log(response.data);
                        toast_.current.show({ severity: 'success', summary: 'Added Successfully', detail: '', life: 3000 });
                        _history(RENDER_URL.CAMP_URL);

                    }
                    else {
                        toast_.current.show({ severity: 'error', summary: ~`${response.data.error}`, detail: '', life: 3000 });
                    }

                    dispatch({
                        type: STOP_LOADING,
                    });
                } catch (error) {
                    _history(RENDER_URL.CAMP_URL);
                }


            }).catch((error) => {
                console.log("Error", error);
                dispatch({
                    type: STOP_LOADING,
                });
            })

        } catch (error) {
            console.log("Error", error);
            dispatch({
                type: STOP_LOADING,
            });
        }


    }

    const onUpdateButtonClick = () => {
        if (validation()) {
            dispatch({
                type: START_LOADING,
            });

            let sel_maps = mapsList.length === selectedMaps.length ? [DR_ALL] : selectedMaps.map(e=>e.symbol);
            let sel_guns = gunsList.length === selectedGuns.length ? [DR_ALL] : selectedGuns.map(e=>e.symbol);
            let param = {
                "tournament_id": tournament_id,
                "name": tournamentTitle,
                "description": tournamentDesc,
                "password": tournamentPassword,
                "maxPlayers": tournamentMaxPlayers,
                "totalParticipants": tournamentTotalPlayers,
                "start": "" + (moment(tournamentStart).utc().valueOf() / 1000),
                "active": isActive ? 1 : 0,
                "test": isTest ? 1 : 0,
                "host": selectedHost.code,
                "maps": sel_maps,
                "guns": sel_guns
            }
            if (isImageChanged) {
                param["image"] = base64Image
            }
            if (tournamentEnds !== "") {
                param["end"] = "" + (moment(tournamentEnds).utc().valueOf() / 1000)
            }

            updateTournament(param).then((response) => {

                if (response.data.success) {
                    let tournamentID = response.data.tournament;

                    toast_.current.show({ severity: 'success', summary: 'Added Successfully', detail: '', life: 3000 });
                    setTimeout(() => {
                        _history(RENDER_URL.TOURNAMENT_URL);
                    }, 1000);

                }
                else {
                    toast_.current.show({ severity: 'error', summary: ~`${response.data.error}`, detail: '', life: 3000 });
                }

                dispatch({
                    type: STOP_LOADING,
                });

            }).catch((error) => {
                console.log("Error", error);

                dispatch({
                    type: STOP_LOADING,
                });
            })
        }
    }


    const onCancelButtonClick = () => {
        _history(RENDER_URL.TOURNAMENT_URL);
    }


    return (
        <main id="main">
            <HeaderContainer />

            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">
                    <Toast ref={toast_} position="top-center" />
                    <div className="card" style={{ width: '100%' }}>

                        <section className='section_tournament'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" placeholder="Enter title" value={tournamentTitle} onChange={(e) => { (e.target.value.length > 100) ? setErrortournamentTitle("Max 100 characters") : settournamentTitle(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {tournamentTitleError}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className="form-group">
                                            <label>Max Players / Server</label>
                                            <input type="number" className="form-control" placeholder="Enter Max Players" value={tournamentMaxPlayers} onChange={(e) => { (e.target.value.length > 100) ? setErrortournamentTitle("Max 100 characters") : settournamentMaxPlayers(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {tournamentMaxPlayersError}
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-sm-3'>
                                        <div className="form-group">
                                            <label>Total Players / Tournament</label>
                                            <input type="number" className="form-control" placeholder="Enter Total Players" value={tournamentTotalPlayers} onChange={(e) => { settournamentTotalPlayers(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {tournamentTotalPlayersError}
                                            </span>
                                        </div>
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <InputTextarea className="form-control" placeholder="Enter description" value={tournamentDesc} onChange={(e) => { (e.target.value.length > 500) ? setErrortournamentDesc('Max 500 characters') : settournamentDesc(e.target.value) }} rows={5} cols={30} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {tournamentDescError}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Upload</label>
                                            <img src={imageURL} width="100px" height="100px" />
                                            {/* <input type="file" className='form-control' onChange={handleFileSelect} /> */}
                                            <input type="file" className='form-control' onChange={(e) => { manageUpload(e) }} />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="text" className="form-control" placeholder="Enter password" value={tournamentPassword} onChange={(e) => { settournamentPassword(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {tournamentPasswordError}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Start</label>
                                            <input type="datetime-local" className="form-control" placeholder="Enter date" value={tournamentStart} onChange={(e) => { settournamentStart(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {tournamentStartError}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>End</label>
                                            <input type="datetime-local" className="form-control" placeholder="Enter date" value={tournamentEnds} onChange={(e) => { settournamentEnd(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {tournamentEndError}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Maps</label>
                                            <br></br>
                                            <MultiSelect value={selectedMaps} onChange={(e) => setSelectedMaps(e.value)} options={mapsList} optionLabel="name"
                                                placeholder="Select Maps" filter maxSelectedLabels={3} className="w-full md:w-20rem" />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {mapsError}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Guns</label>
                                            <br></br>
                                            <MultiSelect value={selectedGuns} onChange={(e) => setSelectedGuns(e.value)} options={gunsList} optionLabel="name"
                                                placeholder="Select Guns" filter maxSelectedLabels={3} className="w-full md:w-20rem" />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {gunsError}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <div className="form-group">
                                            <label htmlFor="switch1" style={{ position: "absolute", width: '100px' }}>Playtest</label>
                                            <InputSwitch inputId="switch1" style={{ marginLeft: '60px', marginTop: '0px' }} checked={isTest} onChange={(e) => setRareStatus(e.value)} />
                                        </div>
                                    </div>

                                    <div className='col-sm-3'>
                                        <div className="form-group">
                                            <label htmlFor="switch2" style={{ position: "absolute", width: '100px' }}>Active</label>
                                            <InputSwitch inputId="switch2" style={{ marginLeft: '60px', marginTop: '0px' }} checked={isActive} onChange={(e) => setActiveStatus(e.value)} />
                                        </div>
                                    </div>

                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Hosted By </label>
                                            <Dropdown value={selectedHost} onChange={(e) => setSelectedHost(e.value)} options={cities} optionLabel="name"
                                                placeholder="Hosted By" className="form-control" />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {selectedHostError}
                                            </span>
                                        </div>
                                    </div>

                                </div>



                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group" style={{ float: 'right' }}>
                                            <Button type="button" icon="pi" label="Cancel" className="p-button-outlined" onClick={() => { onCancelButtonClick() }} />
                                            <Button label="Update" style={{ marginLeft: '5px' }} icon="pi pi-check" className="p-button-success" autoFocus onClick={() => { onUpdateButtonClick() }} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </section>

                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );
}

export default TournamentUpdate;