import { Button } from 'primereact/button';
import React, { useState, useEffect, useRef } from 'react';
import ContentEditable from 'react-contenteditable'
import { useLocation, useNavigate } from 'react-router-dom';
import './Attribute.css';
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { updateAvatarApi, updateUserDetailsApi } from './AttributeService';
import { Toast } from 'primereact/toast';
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { RENDER_URL, START_LOADING, STOP_LOADING, ACDMY_COLLECTION, SET_RUMBLE_LOCAL, validateEmail } from '../common/Constants';
import { getColorCodeForCommunity } from '../common/CommunityColor'
import { InputSwitch } from 'primereact/inputswitch';
import { getDefaultNormalizer } from '@testing-library/react';

function AttributeAssets(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const context = React.useContext(GlobalContext);
    const _history = useNavigate();
    const location = useLocation();
    const passwordDiv = useRef(null);
    const [singleAssets, setAssets] = useState(location.state.selectedData);
    const [currentEmail, setCurrentEmail] = useState(singleAssets.email);
    const userData = context.login.userData;
    const [editMode, setEditMode] = useState(false);
    const [editImage, setChangeImageMode] = useState(false);
    const [modified, setModificationFlag] = useState(false);
    const [userModifed, setUserDetailsModificationFlag] = useState(false);
    const [traitsModified, setTraitsModified] = useState(false);
    const [passwordModifed, setPasswordModificationFlag] = useState(false);
    const [_resetPasswordDiv, setShowResetPassword] = useState(false);
    const communityBadgeNames = context.login.ACDMY_COLLECTION;
    const [checked, setChecked] = useState(singleAssets.active == 1 ? true : false);
    const toast_ = useRef(null);


    const validate = () => {

        if (!validateEmail(singleAssets.username)) {
            toast_.current.show({ severity: 'error', summary: 'Please Enter Valid Username', detail: '', life: 3000 });
            return false;
        }
        else if (singleAssets.password == "") {
            toast_.current.show({ severity: 'error', summary: 'Password Cannot Be Empty', detail: '', life: 3000 });
            return false;
        }
        else {
            return true;
        }
    }


    const onUpdate = () => {

        if (validate()) {
            dispatch({
                type: START_LOADING,
            });

            let params = {
                rows: [singleAssets]
            }

            console.log("updating data", params);

            if (traitsModified) {
                updateAvatarApi(params).then((response) => {
                    if (response.data.success) {

                        dispatch({
                            type: SET_RUMBLE_LOCAL,
                            payload: [],
                        });
                        if (!userModifed) {
                            setModificationFlag(false);
                            toast_.current.show({ severity: 'warn', summary: 'Modified Successfully', detail: '', life: 3000 });
                            dispatch({
                                type: STOP_LOADING,
                            });
                            setTimeout(() => {
                                _history(RENDER_URL.HOME_URL, { state: { refreshList: true } });
                            }, 1000);
                        }
                    } else {
                        console.log("Error");
                    }
                }).then((error) => {
                    console.log("error", error);
                })
            }

            if (userModifed) {
                updateUserDetailApi();
            }
        }

    }

    const updateUserDetailApi = () => {
        let obj = {};
        if (passwordModifed) {
            obj = {
                current_email: currentEmail,
                new_email: singleAssets.username,
                password: singleAssets.password,
                active: singleAssets.active
            }
        } else {
            obj = {
                current_email: currentEmail,
                new_email: singleAssets.username,
                password: "",
                active: singleAssets.active
            }
        }

        let params = {
            rows: [obj]
        }

        updateUserDetailsApi(params).then((response) => {

            if (response.data.success) {
                setUserDetailsModificationFlag(false)
                toast_.current.show({ severity: 'warn', summary: 'Modified Successfully', detail: '', life: 3000 });
                dispatch({
                    type: SET_RUMBLE_LOCAL,
                    payload: [],
                });
                setTimeout(() => {
                    _history(RENDER_URL.HOME_URL, { state: { refreshList: true } });
                }, 1000);
            } else {
                console.log("Error");
            }

            dispatch({
                type: STOP_LOADING,
            });

        }).then((error) => {
            console.log("error", error);
            dispatch({
                type: STOP_LOADING,
            });
        })

    }



    const onCancel = () => {
        _history(RENDER_URL.HOME_URL, { state: { refreshList: false,selectedData:singleAssets } });
    }

    const onEditChanges = (e, key) => {
        setModificationFlag(true);
        if (key == 'username') {
            setUserDetailsModificationFlag(true);
            let value = (e.target.value).trim();
            singleAssets[key] = value;
        }
        else if (key == 'password') {
            setUserDetailsModificationFlag(true);
            setPasswordModificationFlag(true);
            singleAssets[key] = (e.target.value);
        }
        else {
            setTraitsModified(true);
            singleAssets.metadata[key] = (e.target.value).toLowerCase().trim();
        }
        console.log("avatar", singleAssets);
        setAssets(singleAssets);
    }

    const onImageEditChanges = (e, key) => {
        setModificationFlag(true);
        singleAssets[key] = e.target.value;
        setAssets(singleAssets)
    }

    const onActiveStatusChange = (e, key) => {

        console.log(singleAssets);
        //console.log("setting to ",e.value ? 1 : 0);
        singleAssets[key] = e.value ? 1 : 0;
        setChecked(e.value ? true : false)
        setAssets(singleAssets)
        setUserDetailsModificationFlag(true)
        setModificationFlag(true);

    }

    const renderAttribute = (edit) => {

        if (singleAssets.metadata) {
            return (
                Object.entries(singleAssets.metadata).map(([index, object]) => {
                    return <div key={index} className={edit ? "attribute_editable" : "attribute"} >
                        {index}
                        <span></span>
                        <ContentEditable
                            html={object}
                            disabled={editMode ? false : true}
                            onChange={(e) => { onEditChanges(e, index) }}
                            tagName='article'
                        />
                    </div>
                })
            )
        }
        else {
            return (<div className="attribute">No Traits Found </div>)
        }


    }


    const renderAttributeImg = (imageLink) => {

        imageLink = imageLink ? imageLink : "https://icon-library.com/images/ape-icon/ape-icon-14.jpg";
        if (editImage) {
            return <ContentEditable html={imageLink} onBlur={handleBlur} onChange={(e) => { onImageEditChanges(e, 'image') }} />
        }
        else {
            const imgTag = `<img src='${imageLink}' width='400px'/>`
            return <ContentEditable html={imgTag} />
        }
    }
    const resetPasswordShow = (singleAssets) => {
        setModificationFlag(true);
        setUserDetailsModificationFlag(true);
        singleAssets["password"] = "";
        setShowResetPassword(true);
    }

    const onPasteAction = (e, eventName) => {
        e.preventDefault();
        let text = "";
        switch (eventName) {
            case "password":
                text = (e.originalEvent || e).clipboardData.getData('text/plain');
                setUserDetailsModificationFlag(true);
                setPasswordModificationFlag(true);
                singleAssets["password"] = text.trim();
                break;
            case "username":
                text = (e.originalEvent || e).clipboardData.getData('text/plain');
                setUserDetailsModificationFlag(true);
                singleAssets["username"] = text.trim();
                break;
        }

        setModificationFlag(true);

    }

    const changeImage = () => {
        return;
        setChangeImageMode(!editImage)
    }

    const handleBlur = () => {
        changeImage();
    };

    const getCommunity = (isStyle, singleAssets) => {
        if (isStyle) {
            try {
                let x = communityBadgeNames.find(x => x.code === singleAssets.community).name;
                return `communitybadge ${singleAssets.community}`;
            } catch (error) {
                return `communitybadge unknown`;
            }

        }
        else {
            try {
                return communityBadgeNames.find(x => x.code === singleAssets.community).name;
            } catch (error) {
                return singleAssets.community;
            }
        }
    }



    return (
        <main id="main">
            <HeaderContainer />
            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <Toast ref={toast_} position="top-center" />
                <div className="container">
                    <div className="card">
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div className='card'>
                                    <div style={{ padding: '40px' }}>
                                        <div className='d-flex justify-content-center' onDoubleClick={() => { changeImage() }}>
                                            {renderAttributeImg(singleAssets.image)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div style={{ paddingRight: '40px', paddingTop: '40px' }}>
                                    <table className='table table-striped mx-auto'>
                                        <tbody>
                                            <tr>
                                                <th>Email</th>
                                                <td>
                                                    <ContentEditable
                                                        html={singleAssets.email}
                                                        className={editMode ? "editable" : ""}
                                                        disabled={editMode ? false : true}
                                                        onChange={(e) => { onEditChanges(e, 'username') }}
                                                        onPaste={(e) => { onPasteAction(e, "username") }}
                                                        tagName='article'
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Asset ID</th>
                                                <td>{singleAssets.id}</td>
                                            </tr>
                                            <tr>
                                                <th>Assets Name</th>
                                                <td>{singleAssets.asset_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Collection</th>
                                                <td><span>{singleAssets.asset_uuid}</span></td>
                                            </tr>
{/*                                             <tr>
                                                <th>Active</th>
                                                <td><InputSwitch className='_button' checked={checked} onChange={(e) => onActiveStatusChange(e, 'active')} /></td>
                                            </tr> */}
{/*                                             <tr>
                                                <th>Password</th>
                                                <td>{!_resetPasswordDiv ? <span style={{ cursor: 'pointer', color: '#6366F1' }} onClick={() => { resetPasswordShow(singleAssets) }}>Reset Password</span> :
                                                    <ContentEditable
                                                        ref={passwordDiv}
                                                        data-placeholder="enter here"
                                                        html={!_resetPasswordDiv ? "" : singleAssets.password}
                                                        className={editMode ? "editable" : ""}
                                                        disabled={editMode ? false : true}
                                                        onChange={(e) => { onEditChanges(e, 'password') }}
                                                        onPaste={(e) => { onPasteAction(e, "password") }}
                                                        tagName='article'
                                                    />
                                                }</td>
                                            </tr> */}
                                            <tr>
                                                <th>Traits</th>
                                                <td>{singleAssets.metadata ? "" : <span className='p-error block'><b>No Traits Found</b></span>}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {singleAssets.metadata && <div>
                                    <div style={{ paddingRight: '40px', position: 'relative' }}>
                                        <div className='attributes'>
                                            {renderAttribute(editMode)}
                                        </div>
                                    </div>
                                </div>}
                                <div style={{ padding: '40px', float: 'right' }}>
                                    <Button className='p-button-secondary' onClick={onCancel}>Cancel</Button>
                                    {editMode && <Button className={modified ? 'p-button-success ml-2' : 'p-button-secondary ml-2'} icon="pi pi-check" iconPos="right" disabled={modified ? false : true} onClick={() => { onUpdate() }}>Save Changes</Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>
    )
}

export default AttributeAssets;