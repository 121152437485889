import { httpCall, httpFormCall, httpUpload, httpGetCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const geTournamentList = params => {
    return httpCall({
        url: API_URL.TOURNAMENT_LIST,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};

export const geTournamentListCompleted = params => {
    return httpCall({
        url: API_URL.TOURNAMENT_COMPLETED_LIST,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};




export const getTournamentGameList = params => {
    return httpGetCall({
        url: API_URL.TOURNAMENT_GAME_LIST,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};

export const getTournamentExportData = params => {
    return httpGetCall({
        url: API_URL.TOURNAMMENT_GAME_EXPORT,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};


export const getGameDetails = params => {
    return httpGetCall({
        url: API_URL.TOURNAMENT_GAME_LEADERBOARD,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};


export const savetournament = params => {
    return httpFormCall({
        url: API_URL.TOURNAMENT_ADD,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });

};


export const updateTournament = params => {
    return httpFormCall({
        url: API_URL.TOURNAMENT_UPDATE,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });

};



export const saveImagetournament = params => {
    console.log(">>>>>>", params);
    return httpUpload({
        url: API_URL.IMAGE_CAMPAIGN,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const statusChangeCampaign = params => {
    return httpCall({
        url: API_URL.STATUS_CAMPAIGN,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const stopCampaign = params => {
    return httpCall({
        url: API_URL.STATUS_CAMPAIGN,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });


}