import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import moment from 'moment';
import { getCampaignList, saveCampaign, saveImageCampaign } from './CampaignService';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { STOP_LOADING, START_LOADING, RENDER_URL } from '../common/Constants';
import { Toast } from 'primereact/toast';

import { InputTextarea } from 'primereact/inputtextarea';

import { FileUpload } from 'primereact/fileupload';
import { InputSwitch } from "primereact/inputswitch";

import axios from 'axios';

import { Tag } from 'primereact/tag';

function NewCampaignContainer(props) {


    const _history = useNavigate();
    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;

    const [selectedFile, setSelectedFile] = React.useState(null);

    const [campaignName, setCampaignName] = useState("");
    const [campaignNameError, setErrorCampaignName] = useState("");
    const [campaignTitle, setCampaignTitle] = useState("");
    const [campaignTitleError, setErrorCampaignTitle] = useState("");

    const [campaignSubTitle, setCampaignSubTitle] = useState("");
    const [campaignSubTitleError, setErrorCampaignSubTitle] = useState("");

    const [campaignDesc, setCampaignDesc] = useState("");
    const [campaignDescError, setErrorCampaignDesc] = useState("");

    const [campaignMoreUrl, setCampaignMoreUrl] = useState("");
    const [campaignMoreUrlError, setErrorCampaignMoreUrl] = useState("");


    const [campaignStart, setCampaignStart] = useState("");
    const [campaignStartError, setErrorCampaignStart] = useState("");
    const [campaignEnds, setCampaignEnd] = useState("");
    const [campaignEndError, setErrorCampaignEnd] = useState("");
    const [spawnCount, setspawnCount] = useState("");
    const [itemPerGame, setItemsPerGame] = useState("");
    const [itemPerGameError, setItemPerGameError] = useState("");

    const [campaignCountError, setErrorCount] = useState("");
    const [itemCount, setItemsCount] = useState("");
    const [campaignItemCountError, setItemCountError] = useState("");
    const [selectedParticipant, setSelectedParticipants] = useState(null);
    const [participantError, setParticpantError] = useState("");
    const [selectedGuns, setSelectedGuns] = useState(null);
    const [gunsError, setGunsError] = useState("");
    const [selectedItems, setSelectedItems] = useState(null);
    const [itemsError, setItemsError] = useState("");
    const [spawnError, setSpawnError] = useState("");

    const toast_ = useRef(null);

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];


    const [collectionList, setCollectionList] = useState(context.login.ACDMY_COLLECTION)
    const [assetsList, setAssetList] = useState(context.login.ASSET_COLLECTION)
    const [itemsList, setItemsList] = useState(context.login.ITEM_COLLECTION)
    const [isTest, setRareStatus] = useState(true);
    const [base64Image, setBase64Image] = useState("");

    const clearValidation = () => {

        setErrorCampaignName("");
        setErrorCampaignTitle("")
        setErrorCampaignName("")
        setErrorCampaignSubTitle("")
        setErrorCampaignDesc("")
        setErrorCampaignStart("");
        setErrorCampaignEnd("");
        setItemCountError("");
        setSpawnError("");
        setErrorCampaignMoreUrl("");
    }

    const validation = () => {

        clearValidation()
        if (campaignTitle == "") {
            setErrorCampaignTitle("Enter Title");
            return false;
        }
        else if (campaignSubTitle == "") {
            setErrorCampaignSubTitle("Enter Subtitle");
            return false;
        }
        else if (campaignDesc == "") {
            setErrorCampaignDesc("Enter Description");
            return false;
        }

        else if (campaignMoreUrl == "") {
            setErrorCampaignMoreUrl("Enter Url");
            return false;
        }

        else if (campaignStart == "") {
            setErrorCampaignStart("Select Start Date");
            return false;
        }
        /* else if (campaignEnds == "") {
            setErrorCampaignEnd("Select End Date");
            return false;
        } */
        else if (itemCount == "") {
            setItemCountError("Enter Item Count");
            return false;
        }
        else if (spawnCount == "") {
            setSpawnError("Enter Spawn Count");
            return false;
        }
        else if (itemPerGame == "") {
            setItemPerGameError("Enter Item Per Game")
            return false;
        }
        else {
            return true
        }
    }


    const manageUpload = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setBase64Image(base64);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handleSubmit = async (event) => {

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("campaign_id", "32");
        try {

            saveImageCampaign(formData).then((response) => {

                if (response.data.success) {

                    console.log(response.data);
                }
                else {
                    toast_.current.show({ severity: 'error', summary: ~`${response.data.error}`, detail: '', life: 3000 });
                }

                dispatch({
                    type: STOP_LOADING,
                });

            }).catch((error) => {
                console.log("Error", error);

                dispatch({
                    type: STOP_LOADING,
                });
            })

        } catch (error) {
            console.log(error)
        }
    }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
        /* setTimeout(() => {
            console.log("calls")
            handleSubmit();
        }, 2000); */
    }


    const updateImage = (_campaignID) => {

        dispatch({
            type: START_LOADING,
        });

        const formData = new FormData();
        formData.append("image", base64Image);
        console.log(base64Image);
        formData.append("campaign_id", _campaignID);
        try {

            saveImageCampaign(formData).then((response) => {
                try {
                    if (response.data.success) {

                        console.log(response.data);
                        toast_.current.show({ severity: 'success', summary: 'Added Successfully', detail: '', life: 3000 });
                        _history(RENDER_URL.CAMP_URL);
    
                    }
                    else {
                        toast_.current.show({ severity: 'error', summary: ~`${response.data.error}`, detail: '', life: 3000 });
                    }
    
                    dispatch({
                        type: STOP_LOADING,
                    });
                } catch (error) {
                    _history(RENDER_URL.CAMP_URL);
                }
                

            }).catch((error) => {
                console.log("Error", error);
                dispatch({
                    type: STOP_LOADING,
                });
            })

        } catch (error) {
            console.log("Error", error);
            dispatch({
                type: STOP_LOADING,
            });
        }


    }

    const onSaveButtonClick = () => {
        if (validation()) {

            dispatch({
                type: START_LOADING,
            });

            let _selectedParticipant = selectedParticipant.map(e => {
                return e.code;
            })


            let _selectedGuns = selectedGuns.map(e => {
                return e.symbol
            })

            let _selectedItem = selectedItems.map(e => {
                return e.symbol
            })


            let data = null;

            if(campaignEnds == ""){

                data = {
                    campaign: {
                        "name": campaignTitle,
                        "subtitle": campaignSubTitle,
                        "description": campaignDesc,
                        "moreInfoURL": campaignMoreUrl,
                        "start": "" + (moment.utc(campaignStart).valueOf() / 1000),
                        "reset": 1,
                        "itpd": parseInt(itemCount),
                        "tbsp": parseInt(spawnCount),
                        "itpg": parseInt(itemPerGame),
                        "collections": _selectedParticipant,
                        "assets": _selectedGuns,
                        "items": _selectedItem,
                        "test": isTest
                    }
                }

            }else {
                data = {
                    campaign: {
                        "name": campaignTitle,
                        "subtitle": campaignSubTitle,
                        "description": campaignDesc,
                        "moreInfoURL": campaignMoreUrl,
                        "start": "" + (moment.utc(campaignStart).valueOf() / 1000),
                        "end": "" + (moment.utc(campaignEnds).valueOf() / 1000),
                        "reset": 1,
                        "itpd": parseInt(itemCount),
                        "tbsp": parseInt(spawnCount),
                        "itpg": parseInt(itemPerGame),
                        "collections": _selectedParticipant,
                        "assets": _selectedGuns,
                        "items": _selectedItem,
                        "test": isTest
                    }
                }
            }
            


            saveCampaign(JSON.stringify(data)).then((response) => {

                if (response.data.success) {
                    debugger;
                    let campaignID = response.data.campaign;
                    updateImage(campaignID)
                    toast_.current.show({ severity: 'success', summary: 'Added Successfully', detail: '', life: 3000 });
                    // _history(RENDER_URL.CAMP_URL);
                }
                else {
                    toast_.current.show({ severity: 'error', summary: ~`${response.data.error}`, detail: '', life: 3000 });
                }

                dispatch({
                    type: STOP_LOADING,
                });

            }).catch((error) => {
                console.log("Error", error);

                dispatch({
                    type: STOP_LOADING,
                });
            })
        }
    }


    const onCancelButtonClick = () => {
        _history(RENDER_URL.CAMP_URL);
    }


    return (
        <main id="main">
            <HeaderContainer />

            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">
                    <Toast ref={toast_} position="top-center" />
                    <div className="card" style={{ width: '100%' }}>

                        <section className='section_campaign'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" placeholder="Enter title" value={campaignTitle} onChange={(e) => { (e.target.value.length > 100) ? setErrorCampaignTitle("Max 100 characters") : setCampaignTitle(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {campaignTitleError}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        {/*  <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" className="form-control" placeholder="Enter name" value={campaignName} onChange={(e) => { setCampaignName(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {campaignNameError}
                                            </span>
                                        </div> */}
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>SubTitle</label>
                                            <input type="text" className="form-control" placeholder="Enter subtitle" value={campaignSubTitle} onChange={(e) => { (e.target.value.length > 150) ? setErrorCampaignSubTitle("Max 150 characters") : setCampaignSubTitle(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {campaignSubTitleError}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <InputTextarea className="form-control" placeholder="Enter description" value={campaignDesc} onChange={(e) => { (e.target.value.length > 500) ? setErrorCampaignDesc('Max 500 characters') : setCampaignDesc(e.target.value) }} rows={5} cols={30} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {campaignDescError}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Upload</label>
                                            {/* <input type="file" className='form-control' onChange={handleFileSelect} /> */}
                                            <input type="file" className='form-control' onChange={(e) => { manageUpload(e) }} />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>More Info</label>
                                            <input type="text" className="form-control" placeholder="Enter more info url" value={campaignMoreUrl} onChange={(e) => { setCampaignMoreUrl(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {campaignMoreUrlError}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>Start</label>
                                            <input type="date" className="form-control" placeholder="Enter date" value={campaignStart} onChange={(e) => { setCampaignStart(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {campaignStartError}
                                            </span>
                                        </div>
                                    </div>
                                     <div className='col-sm-6'>
                                        <div className="form-group">
                                            <label>End</label>
                                            <input type="date" className="form-control" placeholder="Enter date" value={campaignEnds} onChange={(e) => { setCampaignEnd(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {campaignEndError}
                                            </span>
                                        </div>
                                    </div> 
                                </div>
                                <hr></hr>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className="form-group">
                                            <label>Participants</label>
                                            <br></br>
                                            <MultiSelect value={selectedParticipant} filter onChange={(e) => setSelectedParticipants(e.value)} options={collectionList} optionLabel="name"
                                                placeholder="Select Participants" maxSelectedLabels={3} className="w-full md:w-20rem" />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {participantError}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className="form-group">
                                            <label>Guns</label>
                                            <br></br>
                                            <MultiSelect value={selectedGuns} onChange={(e) => setSelectedGuns(e.value)} options={assetsList} optionLabel="name"
                                                placeholder="Select Guns" filter maxSelectedLabels={3} className="w-full md:w-20rem" />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {gunsError}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className="form-group">
                                            <label>Items</label>
                                            <br></br>
                                            <MultiSelect value={selectedItems} filter onChange={(e) => setSelectedItems(e.value)} options={itemsList} optionLabel="name"
                                                placeholder="Select Item" maxSelectedLabels={3} className="w-full md:w-20rem" />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {participantError}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className="form-group">
                                            <label>Items per day</label>
                                            <input type="number" className="form-control" placeholder="Enter items per day" value={itemCount} onChange={(e) => { setItemsCount(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {itemPerGameError}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className="form-group">
                                            <label>Item per game</label>
                                            <input type="number" className="form-control" placeholder="Enter items per game" value={itemPerGame} onChange={(e => { setItemsPerGame(e.target.value) })} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {spawnError}
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-sm-4'>
                                        <div className="form-group">
                                            <label>Time between spawn (in minutes)</label>
                                            <input type="number" className="form-control" placeholder="Enter time in minutes" value={spawnCount} onChange={(e => { setspawnCount(e.target.value) })} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {spawnError}
                                            </span>
                                        </div>
                                    </div>


                                </div>


                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="form-group">
                                        <label htmlFor="switch1" style={{position:"absolute",width:'100px' }}>Playtest</label>
                                            <InputSwitch inputId="switch1" style={{ marginLeft: '60px', marginTop: '0px' }} checked={isTest} onChange={(e) => setRareStatus(e.value)} />
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group" style={{ float: 'right' }}>
                                            <Button type="button" icon="pi" label="Cancel" className="p-button-outlined" onClick={() => { onCancelButtonClick() }} />
                                            <Button label="Save" style={{ marginLeft: '5px' }} icon="pi pi-check" className="p-button-success" autoFocus onClick={() => { onSaveButtonClick() }} />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </section>

                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );
}

export default NewCampaignContainer;