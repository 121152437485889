import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import ContentEditable from 'react-contenteditable'
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { GlobalDispatchContext } from "../context/Context"
import { validateEmail, LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING,START_LOADING, ITEM_COLLECTION,ASSET_COLLECTION, SET_ASSET, SET_ITEM,SET_MAPS, SET_GUNS, DR_MAPS_COLLECTION, DR_GUNS_COLLECTION } from '../common/Constants';
import { login } from './LoginService'
import './Login.css'
import { Navigate, useNavigate } from 'react-router-dom';
import { ManageLocalStorage } from '../core/LocalStorage';
import { ManageSessionStorage } from '../core/SessionStorage';

import LoginHeaderContainer from '../common/LoginHeaderContainer'


function LoginContainer(props) {

    const dispatch = React.useContext(GlobalDispatchContext)
    const _history = useNavigate();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const [username_error, setUserError] = useState("");
    const [password_error, setPasswordError] = useState("");
    const [error_msg, setErrorMsg] = useState()

    useEffect(() => {
    }, [])

    const clearValidation = () => {
        setUserError("");
        setPasswordError("");
    }

    const onLoginButtonClick = () => {
        clearValidation();
        if (userName.length <= 0) {
            setUserError('Invalid Username');
        }

        else if (password.length < 8) {
            setPasswordError('Password must be at least 8 chars long');
        }
        else {
            clearValidation();
            loginApi();

        }
    }

    const loginApi = () => {

        dispatch({
            type: START_LOADING,
        });


        const loginData = {
            username: userName,
            password: password
        }

        login(loginData).then((response) => {

            if (response.data.success) {
                let userData = {
                    permission: response.data.permissions,
                    user_token: response.data.token
                }

                let collection = response.data.collections
                let assets = response.data.assets
                let items = response.data.items
                let maps = response.data.maps;
                let guns = response.data.guns;
                ManageLocalStorage.set(ACDMY_COLLECTION, collection)
                ManageLocalStorage.set(ASSET_COLLECTION, assets)
                ManageLocalStorage.set(ITEM_COLLECTION, items)
                ManageLocalStorage.set(DR_MAPS_COLLECTION, maps)
                ManageLocalStorage.set(DR_GUNS_COLLECTION, guns)

                dispatch({
                    type: SET_COLLECTION,
                    payload: collection,
                });

                dispatch({
                    type: SET_ASSET,
                    payload: assets,
                });

                dispatch({
                    type: SET_ITEM,
                    payload:items,
                });
                dispatch({
                    type: SET_MAPS,
                    payload:maps,
                });
                dispatch({
                    type: SET_GUNS,
                    payload:guns,
                });

                ManageLocalStorage.set(USER_TOKEN, userData.user_token)
                ManageLocalStorage.set(USER_DATA, userData);

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: userData,
                });

                dispatch({
                    type: STOP_LOADING,
                });
    
                if (userData.permission.admin) {
                    _history(RENDER_URL.HOME_URL);
                }
                else {
                    _history(RENDER_URL.TOURNAMENT_URL);
                }

            }
            else {
                dispatch({
                    type: STOP_LOADING,
                });
                setErrorMsg(response.data.message);
            }
        }).catch((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            setErrorMsg(error.msg)
        })

    }

    return (
        <main>
            <LoginHeaderContainer/>
            <section id="counts" className="counts section-bg" style={{ height: '100vh' }}>
                <div className="container align-items-center" style={{ height: '80vh' }}>
                    <div className="row align-items-center" style={{ height: '80vh' }}>
                        <div className="col-md-6 col-sm-12">
                            <div className='sidenav'>
                                <div className="login-main-text">
                                    <h2>Degen<br />Royale</h2>
                                    <p>Login to continue</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12" style={{ margin: 'auto' }}>
                            <div className="login-form">

                                <div className="form-group">
                                    <span className="p-float-label">
                                        <InputText id="username" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control" />
                                        <label htmlFor="username">Username</label>
                                        <small id="username-help" className="p-error block">{username_error}</small>
                                    </span>
                                </div>
                                <div className="form-group">
                                    <span className="p-float-label">
                                        <Password id="password" value={password} feedback={false} onChange={(e) => setPassword(e.target.value)} />
                                        <label htmlFor="password">Password</label>
                                        <small id="password-help" className="p-error block">{password_error}</small>
                                    </span>
                                </div>

                                {
                                    error_msg && <div className="form-group">
                                        <span className="p-float-label">
                                            <small id="error_help" className="p-error block">{error_msg}</small>
                                        </span>
                                    </div>
                                }


                                <div className="form-group" style={{ float: 'right' }}>
                                    <Button label="Login" icon="pi pi-check" className="p-button-secondary" onClick={() => { onLoginButtonClick() }} autoFocus />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default LoginContainer;