import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import moment from 'moment';
import { addNewsApi } from './NewsService';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { STOP_LOADING, START_LOADING, RENDER_URL } from '../common/Constants';
import { Toast } from 'primereact/toast';
import { Editor } from "primereact/editor";
import { InputTextarea } from 'primereact/inputtextarea';


function AddNewsContainer(props) {


    const _history = useNavigate();
    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;


    const [newsLetterTitle, setnewsLetterTitle] = useState("");
    const [newsLetterTitleError, setErrornewsLetterTitle] = useState("");

    const [newsLetterNote, setnewsLetterText] = useState('');
    const [newsLetterNoteError, setnewsLetterTextError] = useState('');

    const [isPublish, setIsPublish] = useState(false);

    const [selectedFile, setSelectedFile] = React.useState(null);
    const [base64Image, setBase64Image] = useState("");

    const toast_ = useRef(null);

    const clearValidation = () => {
        setnewsLetterTextError("")
        setErrornewsLetterTitle("");
    }

    const validation = () => {

        clearValidation()
        if (newsLetterTitle == "") {
            setErrornewsLetterTitle("Enter news title");
        }
        else if (newsLetterNote == "") {
            setnewsLetterTextError("Enter newsLetter note");
            return false;
        }
        else {
            return true
        }
    }

    const manageUpload = async (event) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setBase64Image(base64);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }


    const onCancelButtonClick = () => {
        _history(RENDER_URL.NEWS_URL);
    }

    const onSaveButtonClick = () => {
        if (validation()) {

            dispatch({
                type: START_LOADING,
            });


            var bodyFormData = new FormData();
    
            try {

                bodyFormData.append("image", base64Image);
                bodyFormData.append("content", newsLetterNote);
                bodyFormData.append("title", newsLetterTitle);
                bodyFormData.append("publish", isPublish);
            } catch (error) {
                console.log(error);
            }

            addNewsApi(bodyFormData).then((response) => {

                if (response.data.success) {
                    console.log(response);
                    dispatch({
                        type: STOP_LOADING,
                    });

                    toast_.current.show({ severity: 'success', summary: `${response.data.message}`, detail: '', life: 3000 });
                    _history(RENDER_URL.NEWS_URL)
                }
            }).catch((error) => {
                console.log(error);
                dispatch({
                    type: STOP_LOADING,
                });
            })


        }
    }


    return (
        <main id="main">
            <HeaderContainer />

            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">
                    <Toast ref={toast_} position="top-center" />
                    <div className="card" style={{ width: '100%' }}>
                        <section className='section_campaign'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>News Title</label>
                                            <input type="text" className="form-control" placeholder="Enter Title" value={newsLetterTitle} onChange={(e) => { (e.target.value.length > 100) ? setnewsLetterTextError("Max 100 characters") : setnewsLetterTitle(e.target.value) }} />
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {newsLetterTitleError}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>Upload Image</label>
                                            {/* <input type="file" className='form-control' onChange={handleFileSelect} /> */}
                                            <input type="file" className='form-control' onChange={(e) => { manageUpload(e) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: '-20px' }}>
                                    <div className='col-sm-12'>
                                        <div className="form-group">
                                            <label>Content</label>
                                            <br></br>
                                            <InputTextarea value={newsLetterNote} onChange={(e) => setnewsLetterText(e.target.value)} rows={5} cols={30} style={{ height: '320px', width: '100%' }} />
                                            {/* <Editor value={newsLetterNote} onTextChange={(e) => setnewsLetterText(e.htmlValue)} style={{ height: '320px' }} /> */}
                                            <span style={{ marginLeft: '5px', fontFamily: 'serif', color: 'red' }}>
                                                {newsLetterNoteError}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className="form-group" style={{ float: 'right' }}>
                                            <Button type="button" icon="pi" label="Cancel" className="p-button-outlined" onClick={() => { onCancelButtonClick() }} />
                                            <Button label="Save" style={{ marginLeft: '5px' }} icon="pi pi-check" className="p-button-success" autoFocus onClick={() => { onSaveButtonClick() }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );
}

export default AddNewsContainer;