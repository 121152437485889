import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import moment from 'moment';
import { addNewsApi, getAllNew, publishNewsApi } from './NewsService';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from 'primereact/multiselect';
import { STOP_LOADING, START_LOADING, RENDER_URL } from '../common/Constants';
import { Toast } from 'primereact/toast';
import { Editor } from "primereact/editor";
import { TabView, TabPanel } from 'primereact/tabview';



function NewsContainer(props) {

    const _history = useNavigate();
    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;
    const [activeIndex, setActiveIndex] = useState(0);
    const [addMode, setAddMode] = useState(userData.permission.add);
    const toast_ = useRef(null);

    const [NewsLetterList, setNewsLetterList] = useState([]);
    const [completedNewsLetterList, setCompletedNewsLetterList] = useState([]);


    useEffect(() => {
        loadAllNews();
    }, []);


    const loadAllNews = () => {
        dispatch({
            type: START_LOADING,
        });

        getAllNew().then((response) => {

            if (response.data.success) {



                let latestDate = Math.max(...response.data.data.map((ele) => new Date(ele.publishedTime)));
                latestDate = new Date(latestDate);
                console.log(latestDate);

                let latestObject = response.data.data.filter((ele) => {
                    let date = new Date(ele.publishedTime);
                    return date.getTime() === latestDate.getTime();
                });


                try {
                    let _data = response.data.data.map(element => {
                        if (latestObject.length > 0) {
                            if (element.id == latestObject[0].id) {

                                element.isActivePublish = true;
                                return element;
                            } else {
                                element.isActivePublish = false;
                                return element;
                            }
                        } else {
                            element.isActivePublish = false;
                            return element;
                        }
                    });

                    setNewsLetterList(_data);
                    dispatch({
                        type: STOP_LOADING,
                    });

                } catch (error) {
                    console.log("Error at timecheck", error);
                    dispatch({
                        type: STOP_LOADING,
                    });
                }


            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });

        })

    }

    const loadNewNews = () => {
        _history(RENDER_URL.CREATE_NEWS_URL)
    }

    const renderDataTableHeader = () => {
        return (
            <div className="flex justify-content-between" style={{ margin: '10px' }}>
                <Button type="button" style={{ visibility: 'hidden' }} icon="pi pi-filter-slash" label="Clear Filters" className="p-button-outlined" />
                <div style={{ float: 'right' }}>
                    {addMode && <Button type="button" style={{ marginLeft: '10px' }} icon="pi pi-user" label="Add News" className="p-button-outlined" onClick={() => { loadNewNews() }} />}
                </div>
            </div>
        )

    }

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
            </span>
        );
    };

    const htmlViewTemplate = (htmlData) => {

        return <Editor value={htmlData.content} readOnly headerTemplate={renderHeader} style={{ minHeight: '150px' }} />
    };

    const addTimeTemplate = (rowData) => {
        return <Moment format="YYYY/MM/DD">
            {rowData.addTime}
        </Moment>
    }

    const modifiedTimeTemplate = (rowData) => {
        return <Moment format="YYYY/MM/DD">
            {rowData.lastModified}
        </Moment>
    }

    const onPublishClicked = (rowData) => {

        dispatch({
            type: START_LOADING,
        });

        let param = {
            news_id: rowData.id
        }

        publishNewsApi(param).then((response) => {

            if (response.data.success) {
                dispatch({
                    type: STOP_LOADING,
                });
                toast_.current.show({ severity: 'success', summary: `${response.data.message}`, detail: '', life: 3000 });
                loadAllNews();
            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });

        })

    };

    const publishButton = (rowData) => {



        return <React.Fragment>
            {(rowData.isActivePublish == false) ?

                <Button label='Publish' onClick={() => onPublishClicked(rowData)} />
                :
                <input type="button" value="Published" className='btn btn-success' style={{ color: 'white', fontSize: 'larger' }} />
            }
        </React.Fragment>
    }


    const onDataRowClick = (event) => {
        console.log(event.data);
        _history(RENDER_URL.SINGLE_NEW, { state: { DATA: event.data } });
    }

    const rowClass = (data) => {
        return {
            'bg-primary_row': data.isActivePublish === true
        };
    };

    return (
        <main id="main">
            <HeaderContainer />

            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">
                    {renderDataTableHeader()}
                    <Toast ref={toast_} position="top-center" />
                    <div className="card">

                        {/*  <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>

                            <TabPanel header="Latest" leftIcon="pi pi-fw"> */}
                        <DataTable value={NewsLetterList} rowClassName={rowClass} onRowClick={(e) => onDataRowClick(e)} responsiveLayout="scroll" >
                            {/* <Column field="content" header="Content" body={htmlViewTemplate}></Column> */}
                            <Column field="title" header="Title"></Column>
                            <Column field="addTime" header="Added Time" body={addTimeTemplate}></Column>
                            <Column field="lastModified" header="Last Modified" body={modifiedTimeTemplate}></Column>
                            <Column field="id" header="Publish" body={publishButton}></Column>
                        </DataTable>
                        {/*  </TabPanel>
                        </TabView> */}

                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );
}

export default NewsContainer;