import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING, ITEM_COLLECTION, ASSET_COLLECTION, SET_ASSET, SET_ITEM } from '../common/Constants';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import { Toast } from 'primereact/toast';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import ExportGroupExcel from '../tournament/ExcelGroupExport';
import { getGameDetails, getTournamentExportData, getTournamentGameList } from './TournamentService';
import { Tag } from 'primereact/tag';

function TournamentStatistics(props) {

    const _history = useNavigate();
    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;
    const [visible, setVisible] = useState(false);

    const location = useLocation();

    const [selectedTournament, setSelectedTournament] = useState(location.state.selectedData);
    const [selectedGame, setSelectedGame] = useState();

    const [selectedID, setSelectedTournamentID] = useState(location.state.selectedData.id);

    const [activeIndex, setActiveIndex] = useState(0);
    const [addMode, setAddMode] = useState(userData.permission.add);

    const [tournamentGameList, setTournamentGameList] = useState([]);

    const [exportData, setExportData] = useState([]);
    const [enableExport, setEnableExport] = useState(true);

    const toast_ = useRef(null);

    const [leaderboardSingleGame, setLeaderBoardData] = useState([])


    useEffect(() => {

        loadTournamentGame(selectedID);
        loadExportData(selectedID);

    }, []);


    const loadExportData = (_tournament_id) => {

        let _param = {
            "tournament": _tournament_id
        }

        getTournamentExportData(_param).then((response) => {

            if (response.data.success) {

                let count = 0;
                let temp = [];
                Object.entries(response.data.data).map(([key, value]) => {
                    count++;
                    let gamename = `Game ${count}`
                    let x = {
                        "sheetTitle": gamename,
                    }

                    let data = []
                    Object.entries(value).map(([key, element]) => {
                        let _data = { ...element }
                        data.push(_data);
                    })

                    let y = {
                        sheetData: data
                    }

                    let z = { ...x, ...y }
                    temp.push(z);
                })

                setExportData(temp);
                setEnableExport(false);
            }


        }).catch((error) => {

            console.log("Error", error);

        })

    }

    const loadTournamentGame = (_tournament_id) => {

        dispatch({
            type: START_LOADING,
        });
        let _param = {
            "tournament": _tournament_id
        }


        getTournamentGameList(_param).then((response) => {

            if (response.data.success) {
                setTournamentGameList(response.data.data);
            }

            dispatch({
                type: STOP_LOADING,
            });

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });

        })

    }

    const loadGameDetails = (tournament_id, session_id) => {

        dispatch({
            type: START_LOADING,
        });
        let _param = {
            "tournament": tournament_id,
            "session": session_id
        }

        getGameDetails(_param).then((response) => {

            if (response.data.success) {

                let data = response.data.data;
                setLeaderBoardData(data);
                setVisible(true);
                dispatch({
                    type: STOP_LOADING,
                });
            }

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });
        })

    }

    const onBack = () => {
        _history(RENDER_URL.TOURNAMENT_URL);
    }

    const onShowDetails = (rowData) => {
        setSelectedGame(rowData);
        loadGameDetails(selectedTournament.id, rowData.session_id)
    }


    const renderLeaderButton = (rowData) => {
        return <Button label='View' className="p-button-outlined" onClick={() => { onShowDetails(rowData) }} />
    }

    const renderRewardButton = (rowData) => {
        return <Button label='Reward' className="p-button-outlined" />
    }

    const positionTemplate = (rowData) => {


        if (rowData.position == 1) {
            return <React.Fragment>
                <Tag severity="warning" value="FIRST"></Tag>
            </React.Fragment>
        }
        else if (rowData.position == 2) {
            return <React.Fragment>
                <Tag severity="info" value="SECOND"></Tag>
            </React.Fragment>
        }
        else if (rowData.position == 3) {
            return <React.Fragment>
                <Tag severity="default" value="THIRD"></Tag>
            </React.Fragment>
        }
        else {
            return <>{rowData.position}</>
        }


    }

    const renderDataTableHeader = () => {
        return (
            <div className="flex justify-content-between" style={{ margin: '10px' }}>
                <div>
                    <h3>{`${selectedTournament.name}`}</h3>
                </div>

                <div style={{ float: 'right' }}>
                    <Button type="button" style={{ marginRight: '10px' }} icon="pi" label="Back" className="p-button-outlined" onClick={() => { onBack() }} />
                    {addMode && <ExportGroupExcel excelData={exportData} enable={enableExport} fileName={`${selectedTournament.name}_export`}></ExportGroupExcel>}
                </div>

            </div>
        )
    }

    const renderTotalPlayer = () => {
        return <>{leaderboardSingleGame.length}</>
    }

    return (
        <main id="main">
            <HeaderContainer />
            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <Toast ref={toast_} position="top-center" />
                <div className="container">
                    {renderDataTableHeader()}

                    <Dialog header={`Leaderboard`} visible={visible} style={{ width: '85vw' }} onHide={() => setVisible(false)}>
                        <DataTable value={leaderboardSingleGame} responsiveLayout="scroll">
                            <Column field="steamName" header="Name"></Column>
                            <Column field="position" header="Position" body={positionTemplate} ></Column>
                            <Column field="points" header="Points"></Column>
                            <Column field="kills" header="Kills"></Column>
                            <Column field="assists" header="Assists"></Column>
                            <Column header="Total Players" body={renderTotalPlayer}></Column>
                            <Column header="Reward" body={renderRewardButton}></Column>
                        </DataTable>
                    </Dialog>


                    <div className="card">
                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="Statitics" leftIcon="pi mr-2">

                                <DataTable value={tournamentGameList} responsiveLayout="scroll">
                                    <Column field="session_id" header="Session"></Column>
                                    <Column field="game" header="Game"></Column>
                                    <Column header="LeaderBoard" body={renderLeaderButton}></Column>
                                </DataTable>

                            </TabPanel>
                        </TabView>

                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );

}

export default TournamentStatistics;