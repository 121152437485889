import { httpCall, httpFormCall } from "../../core/HttpService"
import { API_URL } from "../../common/Constants"

export const getAvatarDetails = params => {
    return httpFormCall({
        url: API_URL.EXPAND_V2,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const addAvatarApi = params => {
    return httpCall({
        url: API_URL.ADD_AVATAR_V2,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


