import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { useNavigate, useLocation } from 'react-router-dom'
import './DataTableDemo.css';
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { getAvatarDashBoardList, updateUserDetailsApi, getAssetsDashboardList } from './DashBoardService';
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { LOGOUT, LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING, ITEM_COLLECTION, ASSET_COLLECTION, SET_ASSET, SET_ITEM, SET_ASSET_LOCAL, SET_RUMBLE_LOCAL } from '../common/Constants';
import { mapCommunityColorCode, getColorCodeForCommunity } from '../common/CommunityColor'
import { ManageLocalStorage } from '../core/LocalStorage';
import { TabMenu } from 'primereact/tabmenu';
import { Tag } from 'primereact/tag';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { ManageSessionStorage } from "../core/SessionStorage";

function DashBoardContainerNew(props) {

    const _history = useNavigate();
    const location = useLocation();
    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;
    const toast_ = useRef(null);
    const [searchParamsName, setUserNameSearch] = useState(location.state ? location.state.selectedData?.username : null);
    const communityBadgeNames = context.login.ACDMY_COLLECTION;

    let _filter = {
        'username': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'community': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'AvatarID': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'active': { value: null, matchMode: FilterMatchMode.EQUALS }
    }

    let _filter_assets = {
        'asset_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
    }

    const verfied_options = [{ code: 1, name: "ACTIVE" }, { code: 0, name: "IN-ACTIVE" }]
    const [activeIndex, setActiveIndex] = useState(0);
    const [rumbleList, setRumbleList] = useState(null);
    const [assetsList, setAssetsList] = useState(null);

    const [userRemovedRumpleList, setUserRemoveRumbleList] = useState(null);
    const [selectedData, setSelectedRowData] = useState(location.state ? location.state.selectedData : null);
    const [selectedAssetData, setSelectedAssetData] = useState(null);
    const [selectedAvatarData, setSelectedAvatarData] = useState(null);
    const [selectedAssetDetails, setSelectedAssetDetails] = useState(null);
    const [editedRumbleList, setEditedRumbleList] = useState([]);
    const [addMode, setAddMode] = useState(userData.permission.add);
    const [editPermission, setEditPermission] = useState(userData.permission.edit);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filters2, setFilters2] = useState(_filter);
    const [filters_Assets, setAssetsFilters] = useState(_filter_assets);

    const [statuses] = useState(['active', 'in active']);

    /**Constructor */
    useEffect(() => {

        if (context.common.localRumbleList && context.common.localRumbleList.length > 0) {
            loadAvatarList(false);
            loadAssetList(false);
        } else {
            loadAvatarList(true);
            loadAssetList(true);
        }
        loadAssetList(true)
    }, [])


    useEffect(() => {
        if (selectedData != null) {
            setUserNameSearch(selectedData.username)
            setActiveIndex(1);
        }
    }, [selectedData])


    useEffect(() => {
        if (selectedAssetData != null) {
            console.log(selectedAssetData);
            setUserNameSearch(selectedAssetData.username)
            setActiveIndex(2);
        }
    }, [selectedAssetData]);


    useEffect(() => {
        if (selectedAvatarData != null) {
            _history("/attribute", { state: { selectedData: selectedAvatarData } });
        }
    }, [selectedAvatarData])

    useEffect(() => {
        if (selectedAssetDetails != null) {
            console.log(selectedAssetDetails);
            _history("/attr_assets", { state: { selectedData: selectedAssetDetails } });
        }
    }, [selectedAssetDetails])




    useEffect(() => {

        let _filter = {
            'username': { value: searchParamsName, matchMode: FilterMatchMode.CONTAINS },
            'community': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'AvatarID': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'active': { value: null, matchMode: FilterMatchMode.EQUALS }
        }

        let _filter_assets = {
            'asset_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'email': { value: searchParamsName, matchMode: FilterMatchMode.CONTAINS }
        }

        setFilters2(_filter);
        setAssetsFilters(_filter_assets);

    }, [searchParamsName]);



    const loadAssetList = (fromLive) => {

        if (fromLive) {
            getAssetsDashboardList().then((response) => {

                if (response.data.success) {
                    let _assetsList = response.data.useassetsrs;
                    setAssetsList(_assetsList);
                    dispatch({
                        type: SET_ASSET_LOCAL,
                        payload: _assetsList,
                    });

                    console.log("asdasda", _assetsList);

                    setLoading(false);
                }
            }).catch((error) => {

            })
        }
    }


    const loadAvatarList = (fromLive) => {

        setLoading(true)
        if (fromLive) {
            getAvatarDashBoardList().then((response) => {
                let _userList = response.data.data.users;
                let _collectionList = response.data.data.collections;
                let assets = response.data.data.assets;
                let items = response.data.data.items;

                let basicRumbleList = _userList.filter((entry) => {
                    if (entry.metadata) {
                        if (("animations" in entry.metadata) || ("charctermesh" in entry.metadata)) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    } else {
                        return false;
                    }
                })

                mapCommunityColorCode(_collectionList);

                ManageLocalStorage.set(ACDMY_COLLECTION, _collectionList)

                dispatch({
                    type: SET_COLLECTION,
                    payload: _collectionList,
                });

                if (assets != undefined) {
                    ManageLocalStorage.set(ASSET_COLLECTION, assets)
                    dispatch({
                        type: SET_ASSET,
                        payload: assets,
                    });
                }

                if (items != undefined) {
                    ManageLocalStorage.set(ITEM_COLLECTION, items)
                    dispatch({
                        type: SET_ITEM,
                        payload: items,
                    });
                }

                setRumbleList(basicRumbleList);
                removeDuplicateUser(basicRumbleList);
                dispatch({
                    type: SET_RUMBLE_LOCAL,
                    payload: basicRumbleList,
                });

                setLoading(false);

            }).catch((error) => {
                console.log(error);
                setLoading(false);
                clearBrowser();
            })



        } else {

            //setting from local sesssion
            let basicRumbleList = context.common.localRumbleList
            setRumbleList(basicRumbleList);
            removeDuplicateUser(basicRumbleList);
            dispatch({
                type: SET_RUMBLE_LOCAL,
                payload: basicRumbleList,
            });
            setLoading(false);
        }
    }

    const clearBrowser = () => {
        dispatch({
            type: LOGOUT,
        });
        ManageLocalStorage.clear();
        ManageSessionStorage.clear();

    }

    const onClearButton = () => {
        setLoading(true);
        setEditMode(false);
        setEditedRumbleList([]);
        setLoading(false);
        setFilters2(_filter)
        setAssetsFilters(_filter_assets)

    }

    const onRefreshClick = (e) => {
        loadAvatarList(true);
        e.preventDefault();

    }

    const removeDuplicateUser = (_rumpleList) => {

        const filteredArr = _rumpleList.reduce((acc, current) => {

            const x = acc.find(item => item.username === current.username);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);




        filteredArr.map((element) => {
            return element.count = 0;
        })


        filteredArr.map((element) => {

            _rumpleList.map(listlement => {
                if (listlement.username == element.username) {
                    if (element.metadata) {
                        let _isArray = Array.isArray(element.metadata)
                        if (_isArray) {
                            element.count = 0;
                        }
                        else {
                            element.count = (element.count == undefined ? 0 : (element.count + 1));
                        }
                    }
                    //element.count = (element.count == undefined ? 1 : (element.count + 1));
                }
            })
            return element;
        });

        setUserRemoveRumbleList(filteredArr);

    }

    const passwordBodyTemplate = (rowData) => {
        if (rowData.passwordModifed)
            return <span style={{ color: 'green' }} >**************<pre>Modified</pre></span>

        return <span>**************</span>
    }



    const onDataRowClick = (event) => {
        if (!editMode) {
            setSelectedRowData(event.data);
        }
    }

    const onShowAvatarDetailsClicked = (event) => {
        setSelectedAvatarData(event.data);
    }

    const onShowAssetDetailsClicked = (event) => {
        setSelectedAssetDetails(event.data)
    }

    const cellEditor = (options) => {
        if (options.field === 'active')
            return activeEditor(options);
        else
            return textEditor(options);
    };

    const textEditor = (options) => {

        return <InputText type="text" onChange={(e) => options.editorCallback(e.target.value)} />;

    };

    const priceEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="USD" locale="en-US" />;
    };

    const activeEditor = (options) => {

        return <Checkbox checked={options.value == 1 ? true : false} onChange={(e) => { options.editorCallback(e.target.checked ? 1 : 0) }} />
    }


    const onCellEditComplete = (e) => {

        let { rowData, newValue, field, originalEvent: event } = e;
        console.log(field);
        let _isModified = false;
        if (rowData[field] != newValue) {
            _isModified = true;
        }

        switch (field) {
            default:
                if (newValue.toString().trim().length > 0) {
                    rowData[field] = newValue;

                    let alreadyPresent = false;
                    let alreadyEditDataPresent = editedRumbleList.map(item => {
                        if (item.AvatarID == rowData.AvatarID) {
                            alreadyPresent = true;
                            if (field == 'password' && _isModified) {
                                item.passwordModifed = true;
                            }
                            item[field] = newValue;
                            return item;
                        }
                        else {
                            if (field == 'password' && _isModified) {
                                item.passwordModifed = true;
                            }
                            return item;
                        }
                    })

                    let tempList = [];
                    if (alreadyEditDataPresent.length > 0 && alreadyPresent) {
                        console.log("ALREADY EDIT DATA PRESENT", alreadyEditDataPresent);
                        tempList = alreadyEditDataPresent;
                    }
                    else {
                        console.log("else", rowData);
                        if (field == "password" && _isModified) {
                            rowData.passwordModifed = true;
                        }
                        tempList = [...editedRumbleList, rowData]
                    }
                    setEditedRumbleList(tempList);
                }
                else
                    event.preventDefault();
                break;
        }
    }

    const onEditClick = (e) => {
        setEditMode(true);
    }


    const onCancelEdit = (e) => {
        setEditMode(false);
        setEditedRumbleList([]);
    }


    const onSaveClicked = () => {
        console.log("save");
        setEditMode(false);
        console.log("edited", editedRumbleList)

        let userUpdatedRows = editedRumbleList.map((data, index) => {
            if (data.passwordModifed) {
                let obj = {
                    current_email: data.username,
                    new_email: data.username,
                    password: data.password,
                    active: data.active
                }

                return obj;
            } else {
                let obj = {
                    current_email: data.username,
                    new_email: data.username,
                    password: "",
                    active: data.active
                }

                return obj;
            }
        })

        console.log(userUpdatedRows);
        update(userUpdatedRows);

    }

    const update = (userUpdatedRows) => {

        let params = {
            rows: userUpdatedRows
        }

        updateUserDetailsApi(params).then((response) => {
            if (response.data.success) {

                toast_.current.show({ severity: 'success', summary: 'Modified Successfully', detail: '', life: 3000 });
                dispatch({
                    type: SET_RUMBLE_LOCAL,
                    payload: [],
                });
                setTimeout(() => {
                    window.location.reload();
                    //_history(RENDER_URL.HOME_URL, { state: { refreshList: true } });
                }, 1000);
            } else {
                console.log("Error");
            }

        }).catch((error) => {
            console.log("error", error);
            dispatch({
                type: STOP_LOADING,
            });
        })
    }


    const onAddAvatarClicked = () => {

        let x = userRemovedRumpleList.filter((e) => {
            if (e.username == searchParamsName) {
                return true;
            }
        })

        // _history(RENDER_URL.NEW_AVATAR_URL);
        _history(RENDER_URL.NEW_AVATAR_URL, { state: { userList: userRemovedRumpleList, selectedUser: x[0] } });

    }


    const onAddAssetsClicked = () => {

        let x = userRemovedRumpleList.filter((e) => {
            if (e.username == searchParamsName) {
                return true;
            }
        })

        _history(RENDER_URL.NEW_ASSETS_URL, { state: { userList: userRemovedRumpleList, selectedUser: x[0] } });

    }







    const renderDataTableHeader = () => {

        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear Filters" className="p-button-outlined" onClick={(e) => { onClearButton() }} />
                <div style={{ float: 'right' }}>
                    {
                        editMode ?
                            <>
                                <Button type="button" style={{ marginLeft: '10px' }} icon="pi pi-user" label="Cancel" className="p-button-outlined" onClick={(e) => { onCancelEdit(e); }} />
                                <Button type="button" style={{ marginLeft: '10px' }} icon="pi " label="Save" className="p-button-outlined" onClick={(e) => { onSaveClicked(e); }} />
                            </>

                            :
                            editPermission &&

                            <Button type="button" style={{ marginLeft: '10px' }} icon="pi pi-user" label="Edit" className="p-button-outlined" onClick={(e) => { onEditClick(e); }} />
                    }
                    <Button type="button" style={{ marginLeft: '10px' }} icon="pi pi-refresh" label="Refresh" className="p-button-outlined" onClick={(e) => { onRefreshClick(e) }} />
                </div>

            </div>
        )
    }
    const renderAvatarTableHeader = () => {

        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear Filters" className="p-button-outlined" onClick={(e) => { onClearButton() }} />
                <Button type="button" icon="pi" label="Add Avatars" className="p-button-outlined" onClick={(e) => { onAddAvatarClicked() }} />
            </div>
        )
    }

    const renderAssetsTableHeader = () => {

        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear Filters" className="p-button-outlined" onClick={(e) => { onClearButton() }} />
                <Button type="button" icon="pi" label="Add Assets" className="p-button-outlined" onClick={(e) => { onAddAssetsClicked() }} />
            </div>
        )
    }

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const verifiedDropItemTemplate = (comVAl) => {
        return <span className={`communitybadge ${comVAl.code}`}>{comVAl.name}</span>;
    }

    const verifiedFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={verfied_options} optionLabel="name" optionValue='code' onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={verifiedDropItemTemplate} placeholder="All" className="p-column-filter" showClear />
    }

    const verifiedBodyTemplate = (rowData) => {

        return <span>{rowData.active == 1 ? <Checkbox checked ></Checkbox> : <Checkbox></Checkbox>}</span>;

        return <span>{rowData.active == 1 ? <i className="pi pi-verified" style={{ color: '#2196f3' }}></i> : <i style={{ color: 'red' }} className="pi pi-exclamation-circle"></i>}</span>;
    }

    const verifiedBodyTemplateOld = (rowData) => {

        return <span>{rowData.active == 1 ? <i className="pi pi-verified" style={{ color: '#2196f3' }}></i> : <i style={{ color: 'red' }} className="pi pi-exclamation-circle"></i>}</span>;
    }

    const avatarTabOpen = (rowData) => {
        if (!editMode) {
            setSelectedRowData(rowData);
        }
    }

    const assetsTabOpen = (rowData) => {
        if (!editMode) {
            setSelectedAssetData(rowData);
        }
    }

    const viewAvatarButton = (rowData) => {

        return <Button label={`${rowData.count} Avatars `} className="p-button-outlined" disabled={rowData.count == 0 ? true : false} onClick={() => { avatarTabOpen(rowData) }} />
    }

    const viewAssetsButton = (rowData) => {

        return <Button label="Assets" className="p-button-outlined" onClick={() => { assetsTabOpen(rowData) }} />
    }



    const filterActiveTemplate = (options) => {

        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Status" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );

    }


    const communityBodyTemplate = (rowData) => {
        try {
            let com_name = communityBadgeNames.find(x => x.code === rowData.community).name
            return <span className={`communitybadge ${rowData.community}`} style={{ backgroundColor: `hsl(${getColorCodeForCommunity(rowData.community)}, 80%, 50%)`, color: "white" }} > {com_name} </span>;
        }
        catch (error) {
            return <span className={`communitybadge unknown ${rowData.community}`}> {rowData.community} </span>;
        }
    }

    const communityDropItemTemplate = (comVAl) => {
        return <span className={`communitybadge ${comVAl.code}`} style={{ backgroundColor: `hsl(${getColorCodeForCommunity(comVAl.code)}, 80%, 50%)`, color: "white" }} >{comVAl.name}</span>;
    }

    const statusRowFilterTemplate = (options) => {

        return <Dropdown value={options.value} options={communityBadgeNames} optionLabel="name" optionValue='code' onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={communityDropItemTemplate} placeholder="community" className="p-column-filter" showClear />
        return <Dropdown optionLabel="name" optionValue='code' options={communityBadgeNames} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={communityDropItemTemplate} placeholder="community" className="p-column-filter" showClear />;
    }

    const imageBodyTemplate = (rowData) => {

        return <img src={`${rowData.image}`} onError={(e) => e.target.src = 'https://icon-library.com/images/ape-icon/ape-icon-14.jpg'} alt={rowData.image} className="product-image img_hover" />;
    }

    const getSeverity = (product) => {
        switch (product.inventoryStatus) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };



    return (
        <main id="main">
            <HeaderContainer />
            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <Toast ref={toast_} position="top-center" />
                <div className="container">
                    <div className="card">
                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="User" leftIcon="pi pi-user mr-2">
                                <DataTable header={renderDataTableHeader} filters={filters2} filterDisplay="row" value={userRemovedRumpleList} editMode="cell" className="editable-cells-table" showGridlines rows={50} paginator paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" loading={loading} responsiveLayout="scroll">
                                    <Column field="username" header="Username" filter filterPlaceholder="Username" style={{ minWidth: '12rem' }} />
                                    {
                                        editMode ?
                                            <Column field="password" header="Password" style={{ minWidth: '12rem' }} body={passwordBodyTemplate} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                            :
                                            <Column field="password" header="Password" style={{ minWidth: '12rem' }} body={passwordBodyTemplate} />
                                    }

                                    {
                                        editMode ?
                                            <Column field="active" header="Active" align="center" filter style={{ minWidth: '12rem' }} body={verifiedBodyTemplate} filterElement={verifiedFilterTemplate} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                            :
                                            <Column field="active" header="Active" align="center" filter style={{ minWidth: '12rem' }} body={verifiedBodyTemplateOld} filterElement={verifiedFilterTemplate} />
                                    }

                                    <Column header="Avatar" align="center" style={{ minWidth: '12rem' }} body={viewAvatarButton} />
                                    <Column header="Assets" align="center" style={{ minWidth: '12rem' }} body={viewAssetsButton} />



                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Avatars" leftIcon="pi pi-eye mr-2">
                                <DataTable header={renderAvatarTableHeader} filters={filters2} filterDisplay="row" value={rumbleList} editMode="cell" className="editable-cells-table" showGridlines onRowClick={(e) => onShowAvatarDetailsClicked(e)} rows={50} paginator paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" loading={loading} responsiveLayout="scroll">
                                    <Column field="username" header="Email" style={{ minWidth: '12rem' }} filter filterPlaceholder="Email" />
                                    <Column field="AvatarID" header="Avatar" style={{ minWidth: '12rem' }} filter filterPlaceholder="Avatar ID" />
                                    <Column field="name" header="Name" filter filterPlaceholder="Email" style={{ minWidth: '12rem' }} />
                                    <Column field="community" header="Community" filter style={{ minWidth: '5rem' }} body={communityBodyTemplate} filterElement={statusRowFilterTemplate} />
                                    <Column field="image" header="Image" style={{ minWidth: '5rem' }} body={imageBodyTemplate} />
                                    {/* <Column field="active" header="Active" align="center" filter style={{ minWidth: '12rem' }} body={verifiedBodyTemplate} filterElement={filterActiveTemplate} /> */}
                                    {/* <Column field="active" header="Active" align="center" filter style={{ minWidth: '12rem' }} body={verifiedBodyTemplateOld} filterElement={verifiedFilterTemplate} /> */}
                                </DataTable>
                            </TabPanel>

                            <TabPanel header="Assets" leftIcon="pi pi-briefcase mr-2">
                                <DataTable header={renderAssetsTableHeader} filters={filters_Assets} filterDisplay="row" value={assetsList} editMode="cell" className="editable-cells-table" showGridlines onRowClick={(e) => onShowAssetDetailsClicked(e)} rows={50} paginator paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" loading={loading} responsiveLayout="scroll">
                                    <Column field="email" header="Email" style={{ minWidth: '12rem' }} filter filterPlaceholder="Email" />
                                    <Column field="asset_name" header="Asset Name" style={{ minWidth: '12rem' }} filter filterPlaceholder="Assets Name" />
                                    <Column field="asset_uuid" header="Collection" style={{ minWidth: '12rem' }} filter filterPlaceholder="Collections" />
                                </DataTable>
                            </TabPanel>

                        </TabView>

                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );
}

export default DashBoardContainerNew;