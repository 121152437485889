import React, { Component, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import './Loader.css'
import { GlobalContext, GlobalDispatchContext } from "../context/Context"
import { ManageLocalStorage } from '../core/LocalStorage';
import { LOGOUT, RENDER_URL } from './Constants';
import { ManageSessionStorage } from "../core/SessionStorage";

function HeaderContainer(props) {

    const location = useLocation(); // once ready it returns the 'window.location' object
    const context = React.useContext(GlobalContext)
    const dispatch = React.useContext(GlobalDispatchContext)
    const showLoader = context.common.isLoading;
    const _history = useNavigate();

    const [currentTab, setCurrentTab] = useState(RENDER_URL.HOME_URL);

    const clearBrowser = (e) => {

        e.preventDefault();
        dispatch({
            type: LOGOUT,
        });
        ManageLocalStorage.clear();
        ManageSessionStorage.clear();
        _history(RENDER_URL.LOGIN_URL);// Load login url when user logs out
    }
    const loginData = context.login;

    useEffect(() => {
        console.log(location.pathname);
        setCurrentTab(location.pathname);
    }, [location]);



    const isActive = (value) => {
        return value == currentTab;
    }


    return (
        <>
            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center">
                    <h1 className="logo me-auto"><Link to="/home">DegenRoyale</Link></h1>
                    <a href="index.html" className="logo me-auto">
                        <img src="%PUBLIC_URL%/assets/img/logo.png" alt="" className="img-fluid" /></a>
                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                        {loginData.userData.permission.admin && <li><Link className={isActive(RENDER_URL.HOME_URL) ? "active" : "not"} to={RENDER_URL.HOME_URL}  ><i className="pi" style={{ padding: '5px' }}></i> Dashboard</Link></li>}
                            {loginData.userData.permission.admin && <li><Link className={isActive(RENDER_URL.CAMP_URL) ? "active" : "not"} to={RENDER_URL.CAMP_URL} ><i className="pi" style={{ padding: '5px' }}></i> Campaign</Link></li>}
                            {/* <li><Link className={isActive(RENDER_URL.CAMP_URL) ? "active" : "not"} to={RENDER_URL.CAMP_URL} ><i className="pi" style={{ padding: '5px' }}></i> Campaign</Link></li> */}
                            <li><Link className={isActive(RENDER_URL.TOURNAMENT_URL) ? "active" : "not"} to={RENDER_URL.TOURNAMENT_URL} ><i className="pi" style={{ padding: '5px' }}></i> Tournament</Link></li>
                            {loginData.userData.permission.admin && <li><Link className={isActive(RENDER_URL.RELEASE_URL) ? "active" : "not"} to={RENDER_URL.RELEASE_URL} ><i className="pi" style={{ padding: '5px' }}></i> Release</Link></li>}
                            {loginData.userData.permission.admin && <li><Link className={isActive(RENDER_URL.NEWS_URL) ? "active" : "not"} to={RENDER_URL.NEWS_URL} ><i className="pi" style={{ padding: '5px' }}></i> News</Link></li>}
                            <li><Link onClick={(e) => { clearBrowser(e) }}><i className="pi pi-sign-in" style={{ padding: '5px' }}></i> Logout</Link></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                </div>
            </header>

            {showLoader && <div className='sample_loader'>
                <div className='loader_center'>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            </div>}
        </>

    )

}

export default HeaderContainer;