import { httpCall, httpFormCall,httpUpload } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const addReleaseNote = params => {
    return httpFormCall({
        url: API_URL.ADD_RELEASE,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const updateReleaseNote = params => {
    return httpFormCall({
        url: API_URL.UPDATE_RELEASE,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const getAllVersion = params =>{
    return httpCall({
        url: API_URL.GET_ALL_RELEASE,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
}

export const getCampaignListCompleted = params => {
    return httpCall({
        url: API_URL.CAMPAIGN_LIST_NOT,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};


export const saveCampaign = params => {
    return httpCall({
        url: API_URL.ADD_CAMPAIGN,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};



export const saveImageCampaign = params => {
    return httpUpload({
        url: API_URL.IMAGE_CAMPAIGN,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


