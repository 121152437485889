import { httpCall, httpFormCall, httpUpload, httpGetCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const getCampaignList = params => {
    return httpCall({
        url: API_URL.CAMPAIGN_LIST,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};

export const getCampaignListCompleted = params => {
    return httpCall({
        url: API_URL.CAMPAIGN_LIST_NOT,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};

export const getCampaignStatitics = params => {
    return httpGetCall({
        url: API_URL.CAMPAIGN_STATIC_LIST,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};


export const saveCampaign = params => {
    return httpCall({
        url: API_URL.ADD_CAMPAIGN,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });

};



export const saveImageCampaign = params => {
    console.log(">>>>>>", params);
    return httpUpload({
        url: API_URL.IMAGE_CAMPAIGN,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const statusChangeCampaign = params => {
    return httpCall({
        url: API_URL.STATUS_CAMPAIGN,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};


export const stopCampaign = params => {
    return httpCall({
        url: API_URL.STATUS_CAMPAIGN,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });


}