import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import HeaderContainer from '../common/HeaderContainer';
import FooterContainer from '../common/FooterContainer'
import { GlobalDispatchContext, GlobalContext } from "../context/Context"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import Moment from 'react-moment';
import moment from 'moment';
import { getCampaignList, getCampaignListCompleted, statusChangeCampaign } from './CampaignService';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { LOGIN_SUCCESS, RENDER_URL, USER_TOKEN, SET_COLLECTION, ACDMY_COLLECTION, USER_DATA, STOP_LOADING, START_LOADING, ITEM_COLLECTION, ASSET_COLLECTION, SET_ASSET, SET_ITEM } from '../common/Constants';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import { Toast } from 'primereact/toast';
import { getCampaignStatitics } from './CampaignService';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import ExportExcel from './ExportExcel';


function CampaignStatistics(props) {

    const _history = useNavigate();
    const dispatch = React.useContext(GlobalDispatchContext);
    const context = React.useContext(GlobalContext);
    const userData = context.login.userData;
    const [visible, setVisible] = useState(false);

    const location = useLocation();

    const [selectedCamp, setSelectedCampaign] = useState(location.state.selectedData);
    const [selectedID, setSelectedCampaignID] = useState(location.state.selectedData.id);

    const [activeIndex, setActiveIndex] = useState(0);
    const [addMode, setAddMode] = useState(userData.permission.add);

    const [campaignStaticList, setCampaignStaticList] = useState([]);

    const [exportData, setExportData] = useState([]);

    const toast_ = useRef(null);


    useEffect(() => {

        loadCampStatitics(selectedID);

    }, []);


    const loadCampStatitics = (_campaign_id) => {

        dispatch({
            type: START_LOADING,
        });
        let _param = {
            "campaign_id": _campaign_id
        }


        getCampaignStatitics(_param).then((response) => {

            if (response.data.success) {
                setCampaignStaticList(response.data.data);
                let temp = [];
                response.data.data.map((element) => {
                    let obj = {
                        'username': element.username,
                        'total': element.total
                    }

                    temp.push(obj);
                })

                setExportData(temp);


            }

            dispatch({
                type: STOP_LOADING,
            });

        }).catch((error) => {

            dispatch({
                type: STOP_LOADING,
            });

        })

    }

    const excelData = [

    ]

    const onBack = ()=>{
        _history(RENDER_URL.CAMP_URL);
    }


    const renderDataTableHeader = () => {
        return (
            <div className="flex justify-content-between" style={{ margin: '10px' }}>
                <div>
                    <h3>{`${selectedCamp.name}`}</h3>
                </div>

                <div style={{ float: 'right' }}>
                <Button type="button" style={{ marginRight: '10px' }} icon="pi" label="Back" className="p-button-outlined" onClick={()=>{ onBack() }}/>
                    {addMode && <ExportExcel excelData={exportData} fileName={`${selectedCamp.name}_export`}></ExportExcel>}
                </div>

            </div>
        )
    }

    return (
        <main id="main">
            <HeaderContainer />
            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <Toast ref={toast_} position="top-center" />
                <div className="container">
                    {renderDataTableHeader()}
                    <div className="card">
                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel header="Statitics" leftIcon="pi mr-2">

                                <DataTable value={campaignStaticList} responsiveLayout="scroll">
                                    <Column field="username" header="Username"></Column>
                                    <Column field="total" header="Total"></Column>
                                </DataTable>

                            </TabPanel>
                        </TabView>

                    </div>
                </div>
            </section>
            <FooterContainer />
        </main>

    );

}

export default CampaignStatistics;