
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import ContentEditable from 'react-contenteditable'
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import HeaderContainer from '../../common/HeaderContainer';
import FooterContainer from '../../common/FooterContainer'
import './dialog.css'
import { Toast } from 'primereact/toast';
import { GlobalDispatchContext, GlobalContext } from "../../context/Context"
import { InputSwitch } from 'primereact/inputswitch';
import { RENDER_URL, START_LOADING, STOP_LOADING, ACDMY_COLLECTION, SET_RUMBLE_LOCAL } from '../../common/Constants';
import { getColorCodeForCommunity } from '../../common/CommunityColor'
import { MultiSelect } from 'primereact/multiselect';
import { getAvatarDetails, addAvatarApi } from './AvatarService'
function AvatarAddContainer(props) {


    const dispatch = React.useContext(GlobalDispatchContext)
    const context = React.useContext(GlobalContext);
    const toast_ = useRef(null);
    const _history = useNavigate();

    const location = useLocation();
    const [displayBasic, setDisplayBasic] = useState(true);
    const [position, setPosition] = useState('center');
    const [selectedUser, setSelectedUser] = useState(location.state.selectedUser);
    const [userList, setUserList] = useState(location.state.userList)
    const [selectedCommunity, setSelectedCommunity] = useState(null);

    const [attributeEditMode, setAttributeEditMode] = useState(true);

    const [token, setToken] = useState("");
    const [tokenErrorMsg, setTokenError] = useState('');

    const [tokenAddress, setTokenAddress] = useState("");
    const [tokenAddressErrorMsg, setAddressTokenError] = useState('');

    const [createAvatar, setNewlyCreatedAvatartList] = useState([]);

    const _communityList = context.login.ACDMY_COLLECTION;

    const [tempAvatartList, setTempAvatarList] = useState([]);

    const [singleAvatarCharactor, setAvatarCharactor] = useState();

    const columns = [
        { field: 'username', header: 'Email' },
        { field: 'community', header: 'Community' },
        { field: 'AvatarID', header: 'Avatar ID' },
        { field: 'name', header: 'Avatar Name' },
    ];


    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }



    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <div>{option.username}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const communityOptionTemplate = (option) => {
        return (
            <span className={`communitybadge ${option.code}`} style={{ backgroundColor: `hsl(${getColorCodeForCommunity(option.code)}, 80%, 50%)`, color: "white" }} >{option.name}</span>
        );
    }

    const countryOptionTemplate = (option) => {

        return (
            <div className="flex align-items-center">
                <div>{option.username}</div>
            </div>
        );
    };

    const onCommunityChange = (e) => {
        setSelectedCommunity(e.value);
    }

    const onFetchAvatar = () => {

        dispatch({
            type: START_LOADING,
        });

        let param = {
            community: selectedCommunity,
            tokenNumber: token,
            tokenAddress: tokenAddress
        }

        getAvatarDetails(param).then((response) => {
            console.log(response);

            if (response.data.success) {
                let avatarDetails = response.data.avatar;
                avatarDetails.username = selectedUser.username;
                delete avatarDetails.password;
                /* let x = [...tempAvatartList,avatarDetails]
                console.log("x",x);

                setTempAvatarList(x) */

                setAvatarCharactor(avatarDetails)
                dispatch({
                    type: STOP_LOADING,
                });
            }
        }).catch((error) => {
            console.log(error);
            dispatch({
                type: STOP_LOADING,
            });
        })

    }

    const onSaveAvatar = () => {
        dispatch({
            type: START_LOADING,
        });

        let params = {
            rows: tempAvatartList
        }
        addAvatarApi(params).then((response) => {

            if (response.data.success) {

                try {
                    if (response.data.failed) {
                        if (response.data.failed.length > 0) {

                            let failedUser = tempAvatartList.map(e => {
                                if (response.data.failed.includes(e.username)) {
                                    return e;
                                }
                            })

                            setTempAvatarList(failedUser);
                            toast_.current.show({ severity: 'error', summary: `Couldn't add these accounts`, detail: '', life: 3000 });
                            console.log("FAILED ACCOUNTS", failedUser);

                        }
                        else {
                            toast_.current.show({ severity: 'success', summary: 'Successfully Added', detail: '', life: 3000 });
                            setTempAvatarList([]);
                            dispatch({
                                type: SET_RUMBLE_LOCAL,
                                payload: [],
                            });
                            setTimeout(() => {
                                _history(RENDER_URL.HOME_URL, { state: { refreshList: true } });
                            }, 1000);
                        }
                    }
                    else {
                        toast_.current.show({ severity: 'success', summary: 'Successfully Added', detail: '', life: 3000 });
                        setTempAvatarList([]);
                        dispatch({
                            type: SET_RUMBLE_LOCAL,
                            payload: [],
                        });
                        setTimeout(() => {
                            _history(RENDER_URL.HOME_URL, { state: { refreshList: true } });
                        }, 1000);
                    }

                }
                catch (error) {
                    console.log("error", error);
                    dispatch({
                        type: SET_RUMBLE_LOCAL,
                        payload: [],
                    });

                    setTimeout(() => {
                        _history(RENDER_URL.HOME_URL, { state: { refreshList: true } });
                    }, 1000);
                }

            } else {
                dispatch({
                    type: STOP_LOADING,
                });
            }

            dispatch({
                type: STOP_LOADING,
            });

        }).then((error) => {
            dispatch({
                type: STOP_LOADING,
            });
            console.log("error", error);
        })
    }


    const onAddToList = () => {
        let x = [...tempAvatartList, singleAvatarCharactor]
        setTempAvatarList(x)
        dialogFuncMap[`displayBasic`](false);
        resetValues();

    }

    const resetValues = () => {
        setAvatarCharactor(null);
        setSelectedCommunity(null);
        setToken('');
        setTokenAddress('');
    }

    const onDiscardList = () => {
        setTempAvatarList([]);
        _history(RENDER_URL.HOME_URL);
    }


    const renderAttribute = () => {
        if (singleAvatarCharactor) {
            if (singleAvatarCharactor.metadata) {
                return (
                    Object.entries(singleAvatarCharactor.metadata).map(([index, object]) => {
                        return <div key={index} className="attribute" >
                            {index}
                            <span></span>
                            <ContentEditable
                                html={object}
                                disabled={attributeEditMode ? false : true}
                                onChange={(e) => { onAttributeEditChanges(e, index) }}
                                tagName='article'
                            />
                        </div>
                    })
                )
            }
        }
        else {
            return <></>
        }
    }

    const onAttributeEditChanges = (e, key) => {
        singleAvatarCharactor.metadata[key] = e.target.value;
        setAvatarCharactor(singleAvatarCharactor);
    }

    return (
        <main id="main">
            <HeaderContainer />
            <section id="counts" className="counts section-bg" style={{ marginTop: '8vh' }}>
                <div className="container">

                    <Toast ref={toast_} position="top-center" />

                    <Dialog header="Add Avatar" visible={displayBasic} style={{ width: '65vw' }} onHide={() => onHide('displayBasic')}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-sm-12">
                                    <div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <Dropdown value={selectedUser} onChange={(e) => setSelectedUser(e.value)} options={userList} optionLabel="username" placeholder="Select a User" filter="true" filterPlaceholder='Search Username' filterInputAutoFocus="true"
                                                    valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="form-control" />
                                            </div>
                                            <div className='col-sm-6'>
                                                <Dropdown className='form-control' value={selectedCommunity} options={_communityList} itemTemplate={communityOptionTemplate} onChange={onCommunityChange} optionLabel="name" optionValue='code' placeholder="Select Community" />
                                            </div>
                                        </div>

                                        <div className='row' style={{ marginTop: '10px' }}>
                                            <div className='col-sm-6'>
                                                <InputText className='form-control' placeholder='Token number' value={token} onChange={(e) => setToken(e.target.value)} />
                                                <small id="token-help" className="p-error block">{tokenErrorMsg}</small>
                                            </div>
                                            <div className='col-sm-6'>
                                                <InputText className='form-control' placeholder='Token address' value={tokenAddress} onChange={(e) => setTokenAddress(e.target.value)} />
                                                <small id="username-help" className="p-error block">{tokenAddressErrorMsg}</small>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        {singleAvatarCharactor && <div className="row">
                                            <div className="col-sm-4">
                                                {/* <div>
                                        <div className='d-flex justify-content-center' onDoubleClick={() => { changeImage() }}>
                                            {renderAttributeImg(singleAvatarCharactor.image)}
                                        </div>
                                    </div> */}
                                                <img src={singleAvatarCharactor.image ? singleAvatarCharactor.image : "https://icon-library.com/images/ape-icon/ape-icon-14.jpg"} width='280px' />
                                            </div>
                                            {singleAvatarCharactor.metadata ? <div className="col-sm-8">
                                                <div className='attributes'>
                                                    {renderAttribute()}
                                                </div>
                                            </div> : <div className="col-sm-8"><span className='p-error block'><b>No Traits Found</b></span></div>}
                                        </div>}

                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                {
                                                    singleAvatarCharactor ?
                                                        <Button type="button" label="Add To List" className="p-button-outlined right_float" onClick={(e) => { onAddToList() }} />
                                                        :
                                                        <Button type="button" label="Fetch Avatar" className="p-button-outlined right_float" onClick={(e) => { onFetchAvatar() }} />

                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <hr></hr>

                        </div>
                    </Dialog>

                    <div className="row">
                        <div>
                            <Button label='Add New Avatar' icon="pi pi-sign-in" className="p-button-secondary" onClick={() => onClick('displayBasic')} />
                            {tempAvatartList.length > 0 && <div className='row d-flex' style={{ float: 'right' }}>
                                <div >
                                    <Button label='Discard' className='p-button-secondary' onClick={() => { onDiscardList() }}></Button>
                                    <Button label='Save' className='p-button-success' onClick={() => { onSaveAvatar() }}></Button>
                                </div>
                            </div>}
                        </div>
                    </div>

                    <br></br>
                    <div className="card">
                        <div className="card-fluid">
                            <div className="row">
                                <DataTable value={tempAvatartList} className="editable-cells-table" responsiveLayout="scroll" emptyMessage="Add new avatar">
                                    {
                                        columns.map(({ field, header }) => {
                                            return <Column key={field} field={field} header={header} style={{ width: '25%' }} />
                                        })

                                    }
                                </DataTable>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <FooterContainer />
        </main>
    );


}

export default AvatarAddContainer;
