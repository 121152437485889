import { httpCall, httpFormCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const updateAvatarApi = params => {
    return httpCall({
        url: API_URL.UPDATE_AVATAR,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

export const updateUserDetailsApi = params => {
    return httpCall({
        url: API_URL.UPDATE_USER,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};

