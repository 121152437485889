import { httpCall, httpFormCall } from "../core/HttpService"
import { API_URL } from "../common/Constants"

export const getAvatarDashBoardList = params => {
    return httpFormCall({
        url: API_URL.AVATAR_LIST_V2,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });
};




export const getAssetsDashboardList = params => {

    return httpCall({
        url: API_URL.ASSETS_LIST,
        method: "get",
        data: params
    }).then(response => {
        return response;
    });

}

export const updateUserDetailsApi = params => {
    return httpCall({
        url: API_URL.UPDATE_USER,
        method: "post",
        data: params
    }).then(response => {
        return response;
    });
};
